import React, {useLayoutEffect, useRef, useState} from 'react';
import {Card, CardBody, CardHeader} from "reactstrap";
import Release from "./Release";
import {Link} from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import cx from "classnames";
import {VerticalTimeline} from "react-vertical-timeline-component";
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faExclamation} from '@fortawesome/free-solid-svg-icons'

import './LatestReleases.css';
import {formatTimestampToDate} from "../../../utils/date";
import Markdown from "../../App/components/Markdown";

export default function LatestReleases({
                                           title = "Last releases",
                                           showActions = true,
                                           showLink = true,
                                           showCard = true,
                                           showIcon = false,
                                           showCardHeader = true,
                                           library,
                                           allReleases,
                                           service,
                                           top = 10,
                                           fitSizeToParent = false
                                       }) {
    const targetRef = useRef();
    const [dimensions, setDimensions] = useState({width: 0, height: 0});

    useLayoutEffect(() => {
        if (fitSizeToParent && targetRef.current) {
            setDimensions({
                width: targetRef.current.offsetWidth,
                height: targetRef.current.offsetHeight
            });
        }
    }, []);

    const releases = allReleases.slice(0, top);
    if (!releases || releases.length === 0) {
        return null;
    }

    const scrollBarStyle = {};
    const style = {};

    if (fitSizeToParent && dimensions.height !== 0) {
        scrollBarStyle['maxHeight'] = dimensions.height - 50;
    }

    if (fitSizeToParent) {
        style['height'] = "100%"
    }

    const releaseLink = library ? library.linkToReleases() : service.linkToRegistryReleases();

    const content = <>
        {
            showCardHeader &&
            <CardHeader className="card-header-tab">
                <div className="card-header-title">
                    {showIcon && <i className="header-icon lnr-heart-pulse icon-gradient bg-tempting-azure"> </i>}
                    {showLink && <Link to={releaseLink}>{title}</Link>}
                    {!showLink && <span>{title}</span>}
                </div>
                {
                    showActions &&
                    <div className="btn-actions-pane-right">
                        <LastReleaseBadge release={releases[0]} style={{marginLeft: "5px", marginBottom: "8px"}}/>
                    </div>
                }
            </CardHeader>
        }
        <CardBody className="pt-2" innerRef={targetRef}>
            <div className={cx({"scroll-area-lg": library})} style={scrollBarStyle}>
                <PerfectScrollbar>
                    <div className="p-4">
                        <VerticalTimeline layout="1-column">
                            {releases.map(release => {
                                return <Release key={release.Date} release={release} showServiceLink={true}
                                                asTimelineItem={true}/>
                            })}
                        </VerticalTimeline>
                    </div>
                </PerfectScrollbar>
            </div>
        </CardBody>
    </>;

    return <>
        {
            showCard &&
            <Card className={cx("latest-releases", {"mb-2": !library})} style={style}>
                {content}
            </Card>

        }
        {!showCard && content}
    </>
}

export function LastReleaseBadge({release, service, style, withTitle = false, withDescription = false}) {
    const [id] = useState("id-" + Math.random().toString(16).replace(".", ""));

    if (release === undefined && !service.hasReleases()) {
        return null;
    }

    const lastRelease = release !== undefined ? release : service.getReleases()[0];

    const now = Math.round(new Date().getTime() / 1000);
    const daysSince = Math.round((now - lastRelease.Date) / (24 * 60 * 60));

    let type = "success";
    let extreme = false;

    switch (true) {
        case daysSince < 30:
            type = "success";
            break;

        case daysSince < 90:
            type = "warning";
            break;

        case daysSince < 180:
            type = "warning";
            extreme = true;
            break;

        case daysSince > 365:
            type = "danger";
            extreme = true;
            break;

        default:
            type = "danger";
            break;
    }

    let daysSinceEntry;

    switch (daysSince) {
        case 0:
            daysSinceEntry = "today";
            break;
        case 1:
            daysSinceEntry = "yesterday";
            break;
        default:
            daysSinceEntry = daysSince + " days ago";
    }

    return <span className={"badge badge-" + type + " badge-pill last-release-badge"} style={style}
                 id={id}>
        {
            extreme && <FontAwesomeIcon icon={faExclamation}/>
        }
        {
            withTitle && <>Last release:</>
        }
        &nbsp;{daysSinceEntry}
        {
            extreme && <>&nbsp;<FontAwesomeIcon icon={faExclamation}/></>
        }
        {
            withDescription &&
            <UncontrolledTooltip target={id} innerClassName={"last-release-badge-tooltip"}>
                <p><b>Latest release {formatTimestampToDate(lastRelease.Date)}: {lastRelease.Release}
                    {
                        lastRelease.Release !== lastRelease.Title && lastRelease.Title !== "" &&
                        <span> - {lastRelease.Title}</span>
                    }</b></p>
                <Markdown source={lastRelease.Description}/>
            </UncontrolledTooltip>
        }
    </span>
}