import cx from "classnames";
import React from "react";

import './index.css'
import {Link} from "react-router-dom";

export default function PageTitle({heading, subheading, icon, linkBack, children, img}) {
    return <div className="app-page-title">
        <div className="page-title-wrapper">
            <div className="page-title-heading">
                {/*<div*/}
                {/*    className={cx("page-title-icon", {'d-none': !icon && !img})}>*/}
                {/*    {*/}
                {/*        icon && <i className={icon}/>*/}
                {/*    }*/}
                {/*    {img}*/}
                {/*</div>*/}
                {img}
                <div>
                    {
                        linkBack &&
                        <Link to={linkBack}><i className="lnr-chevron-left"> </i></Link>
                    }
                    {heading}
                    <div
                        className={cx("page-title-subheading", {'d-none': !subheading})}>
                        {subheading}
                    </div>
                </div>
            </div>
            <div className="page-title-actions">
                {children}
            </div>
        </div>
    </div>
}