import React from 'react';
import {ServiceRiskBadge} from "../../App/components/RiskBadge";
import {LastReleaseBadge} from "./LatestReleases";
import {Link} from "react-router-dom";

import "./ServiceTitle.css";

export default function ServiceTitle({service, withLink = false, withRelease=true, withReleaseDescription = true, style, className}) {
    return <span className={"service-title " + className} style={style}>
        <ServiceRiskBadge service={service}/>
        {withLink && <>&nbsp;<Link to={service.linkToRegistryOverview()}>{service.getName()}</Link>&nbsp;</>}
        {!withLink && <>&nbsp;{service.getName()}&nbsp;</>}
        {withRelease && <LastReleaseBadge service={service} withDescription={withReleaseDescription}/>}
    </span>
}