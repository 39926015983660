import Risk, {RISK_SEVERITY_MEDIUM} from "../risk";

export class ServiceHasNoPassportRisk extends Risk {
    id = "NO_PASSPORT";
    title = "Service has no passport";
    severity = RISK_SEVERITY_MEDIUM;
    explanation = [
        `Missing passport blocks early detection of variety of other risks and prolongs new team members onboarding time.`
    ];
    description = (service) => {
        return `Passport is missing.`
    };

    enables = [
        `Feature: TechRadar`,
        `Feature: Owner virtual library`,
        `Feature: Context virtual library`,
        `Feature: Contributor tech portfolio`,
        `Risk detection: Ownerless service`,
        `Risk detection: Tech related risks`,
        `Risk detection: Status related risks`,
    ];

    mitigation = [
        `Introduce <a href="https://service360.io/docs/service-passport/">Service Passport</a>`
    ];
    detailsLink = "/docs/risk-glossary/#service-has-no-passport";
    detectFunc = srv => !srv.hasPassport() || Object.keys(srv.srv.Passport.Passport).length < 4 || srv.srv.Passport.Passport["__IsFake"] !== undefined;
}
