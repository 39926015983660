import Risk, {RISK_SEVERITY_CRITICAL} from "../risk";

const IsAbandonedAfterDays = 365;

export class ServiceIsAbandonedRisk extends Risk {
    id = "ABANDONED";
    severity = RISK_SEVERITY_CRITICAL;
    title = "Service is abandoned?";

    detectFunc = srv => srv.getDaysSinceLastRelease() >= IsAbandonedAfterDays;

    description(service) {
        const n = service === undefined ? "> " + IsAbandonedAfterDays : service.getDaysSinceLastRelease();
        return `Service was not released in the last <b>${n}</b> days. 
                    <br />There is a huge chance that it is abandoned.`
    };

    explanation = [
        `Abandoned production services (abandonware) impose direct threat to normal business operations mode due to <a href="https://en.wikipedia.org/wiki/Software_rot">Software Rot</a>`
    ];

    mitigation = [
        "Schedule service audit session",
        "Retire the service"
    ];

    detailsLink = "/docs/risk-glossary/#abandoned-service";
}