import Risk, {RISK_SEVERITY_INFO} from "../../risk";
import {isBoilerplateFolder} from "../../../domain/serviceDocs";

export class ServiceHasNoHowToDocumentationRisk extends Risk {
    id = "NO_HOWTO_DOCUMENTATION";
    title = "Service has no how-to runbooks documentation";
    severity = RISK_SEVERITY_INFO;
    explanation = [
        `How-to runbooks are used to document the most common manual 
        operations done for the service maintenance.`,
        `How-to runbooks significantly reduce risks of knowledge-loss and save time and mental capacity needed to perform
        manual operations`
    ];
    description = (service) => {
        return `How-to runbooks documentation for the service is missing.`
    };
    mitigation = [
        `Introduce service how-to runbooks documentation`
    ];
    detailsLink = "https://github.com/Service360/ServiceBoilerplate/tree/master/docs/how-to";

    detectFunc = srv => (srv.isWebService() || srv.isMobileApp() || srv.isLibrary() || srv.isEmbedded() || srv.isDesktopApp()) && (!srv.getDocs().getHowToDocs() || isBoilerplateFolder(srv.getDocs().getHowToDocs()))
}