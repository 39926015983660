import Risk, {RISK_SEVERITY_MEDIUM} from "../risk";
import {TECH_ASSESS, TECH_TRIAL} from "../../domain/tech";

export class ServiceUsesTooManyNonADOPTTechnologiesRisk extends Risk {
    id = "TOO_MANY_NON_ADOPT_TECH";
    title = "Service uses too many ASSESS/TRIAL technologies";
    severity = RISK_SEVERITY_MEDIUM;
    explanation = [
        `Every non-production proven (ASSESS/TRIAL) technology used in the project exponentially increases project/service
        risks`
    ];
    description = (service) => {
        let descr = `Service uses too many (> 3) technologies, which are marked as ASSESS/TRIAL in TechRadar`;

        if (service) {
            descr += ": <ul><li>"+getAssessTrialTechs(service).map(x => x.getName()).join("</li><li>") + "</li></ul>"
        }

        return descr
    };
    mitigation = [
        `Consider reducing the amount of the risk inducing technologies by replacing them with ADOPT alternatives`,
        `Consider promotion of ADOPT/TRIAL technologies to ADOPT in TechRadar`,
    ];
    detailsLink = "/docs/risk-glossary/#service-uses-too-many-assesstrial-technologies";

    detectFunc = srv => {
        return getAssessTrialTechs(srv).length > 3
    }
}

function getAssessTrialTechs(srv) {
    if (!srv.lib.getTechRadar().isDedicatedRadar()) {
        return [];
    }

    return Object.keys(srv.getTech() || {})
        .map(x => srv.lib.getTechRadar().getTech(x))
        .filter(x => x.getState() === TECH_ASSESS ||x.getState() === TECH_TRIAL)
}