import React from 'react';

import cx from "classnames";
import ReactCSSTransitionGroup from "react-addons-css-transition-group";
import HeaderLogo from "./HeaderLogo";
import Menu, {MenuNavDots} from "./Menu";
import UserBox from "./UserBox";
import SearchBox from "./SearchBox";
import Hamburger from "react-hamburgers";
import {Button} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faEllipsisV} from "@fortawesome/free-solid-svg-icons";
import useTheme from "../../../../hooks/useTheme";
import {Link} from "react-router-dom";
import RightDrawer from "./RightDrawer";

function TopNavBar({organisation, library, noSidebarToggle = false, noTopNaviToggle = false}) {
    const {mobileSidebarOpen, mobileTopNaviOpen, toggleMobileSidebar, toggleMobileTopNavi} = useTheme();

    return <>
        <ReactCSSTransitionGroup
            component="div"
            className={cx("app-header", "header-shadow", {"with-filter-navi": library && library.isFiltered()})}
            transitionName="HeaderAnimation"
            transitionAppear={true}
            transitionAppearTimeout={1500}
            transitionEnter={false}
            transitionLeave={false}>

            <HeaderLogo org={organisation} library={library}/>

            <>
                {
                    !noSidebarToggle &&
                    <div className="app-header__mobile-menu" style={{paddingRight: 0}}>
                        <div onClick={toggleMobileSidebar}>
                            <Hamburger
                                active={mobileSidebarOpen}
                                type="elastic"
                                onClick={toggleMobileSidebar}
                            />
                        </div>
                    </div>
                }
                {
                    !noTopNaviToggle &&
                    <div className="app-header__menu">
                    <span onClick={toggleMobileTopNavi}>
                        <Button size="sm"
                                className={cx("btn-icon btn-icon-only", {active: mobileTopNaviOpen})}
                                color="primary"
                                onClick={toggleMobileTopNavi}>
                            <div className="btn-icon-wrapper"><FontAwesomeIcon icon={faEllipsisV}/></div>
                        </Button>
                    </span>
                    </div>
                }
            </>

            <div className={cx(
                "app-header__content",
                {'header-mobile-open': mobileTopNaviOpen},
            )}>
                <div className="app-header-left">
                    <SearchBox library={library}/>
                    <Menu library={library}/>
                </div>
                <div className="app-header-right">
                    <MenuNavDots library={library} className={"d-lg-none d-md-flex"}/>
                    <UserBox org={organisation} library={library}/>
                    <RightDrawer org={organisation} library={library}/>
                    {/*<HeaderRightDrawer/>*/}
                </div>
            </div>
        </ReactCSSTransitionGroup>
        {
            library && library.isFiltered() &&
            <div className="filter-navi-bar">
                You are viewing filtered library: <Link to={library.link()}>{library.getName()}</Link> | Go to main library: <Link to={library.parentLib.link()}>{library.parentLib.getName()}</Link>
            </div>
        }
    </>;
}


export default TopNavBar;
