import Service from "./service";
import Contributor from "./contributor";
import ReleasesOverview from "./releasesOverview";
import TechRadar from "./techradar";
import ContributionsByYear from "./contributionsByYear";
import {avg, median, p100} from "../utils/math";
import unique from "../utils/filter";

export default class Library {
    isLoaded = false;

    constructor(org, id, name, details, loader) {
        this.org = org;
        this.id = id;
        this.name = name;
        this.details = details;
        this.lib = {
            "Services": [],
        };
        this.loadFailed = false;
        this.services = [];
        this.pluginConfigs = {};
        this.releasesOverview = new ReleasesOverview(this);
        this.contributionsByYear = {};
        this.loader = loader;
    }

    getName() {
        return this.name;
    }

    //Unified interface which allows unlimited nesting of filteredLibs
    getMainLibrary() {
        let t = this;

        while (t.parentLib !== undefined) {
            t = t.parentLib
        }

        return t;
    }

    getIdForDataRequest() {
        return this.id;
    }

    getOrg() {
        return this.org;
    }

    getContributionsByYear(year) {
        if (!this.contributionsByYear[year]) {
            this.contributionsByYear[year] = new ContributionsByYear(this, year);
        }

        return this.contributionsByYear[year];
    }

    getReleasesOverview() {
        return this.releasesOverview;
    }

    async ready(ignoreIsLoaded = true) {
        if (this.isLoaded && !ignoreIsLoaded) {
            return;
        }

        const result = await this.loader.get(this.getDataURI());

        this.lib = result.data;
        this.services = Object.keys(this.lib.Services).map((srvId) => {
            return new Service(srvId, this.lib.Services[srvId], this, this.loader);
        });

        this.techradar = new TechRadar(this, this.lib.TechRadarOverview);
        this.pluginConfigs = this.lib.Plugins;

        this.isLoaded = true;
    }

    getPluginConfigs() {
        return this.pluginConfigs;
    }

    getPluginsContext() {
        return "lib#" + this.getIdForDataRequest();
    }

    getDataURI() {
        return "/v1/orgs/" + this.org.getId() + "/libraries/" + this.getIdForDataRequest()
    }

    getLastMonthActiveServices() {
        return this.getServices().filter(x => x.getActivityScore() > 0).sort((a, b) => {
            return a.getActivityScore() > b.getActivityScore() ? -1 : 1;
        });
    }

    getActiveServices() {
        return this.getServices().filter(x => x.isActive()).sort((a, b) => {
            return a.getName() > b.getName() ? -1 : 1;
        });
    }

    getServices() {
        return this.services;
    }

    getService(id) {
        return this.getServices().find((srv) => {
            return srv.id === id;
        })
    }

    getServiceByName(name) {
        return this.getServices().find((srv) => {
            return srv.getName() === name;
        })
    }

    getContributors() {
        if (this._contributors === undefined) {
            this._contributors = {};
            // this._userProfilesMap = {};
            for (const srv of this.getServices()) {
                if (!srv.hasContributors()) {
                    continue;
                }
                for (const c of srv.getContributors()) {
                    // if (!this._userProfilesMap[c.Login]) {
                    //     this._userProfilesMap[c.Login] = this.org.getUserProfilesForContributor(c)
                    //
                    //     if (this._userProfilesMap[c.Login].length === 0) {
                    //         this._userProfilesMap[c.Login] = [new UserShortProfile(this.org, {
                    //             EMail: c.Login
                    //         })]
                    //     }
                    // }
                    //
                    // this._userProfilesMap[c.Login].forEach(x => {
                    //     if (this._contributors[x.getEmail()] === undefined) {
                    //         this._contributors[x.getEmail()] = new Contributor(this, srv, c);
                    //     } else {
                    //         this._contributors[x.getEmail()].addContribution(srv, c);
                    //     }
                    // });

                    if (this._contributors[c.Login] === undefined) {
                        this._contributors[c.Login] = new Contributor(this, srv, c);
                    } else {
                        this._contributors[c.Login].addContribution(srv, c);
                    }
                }
            }

            this._contributors = Object.values(this._contributors).sort((a, b) => {
                return a.getScore() < b.getScore() ? 1 : -1;
            });
        }

        return this._contributors;
    }

    getContributor(login) {
        return this.getContributors().find(x => x.Login === login)
    }

    getTechRadar() {
        return this.techradar;
    }

    getTech(name) {
        return this.getTechRadar().getTech(name);
    }

    getReleases() {
        let releases = [];

        for (const srv of this.getServices()) {
            if (!srv.hasReleases()) {
                continue;
            }

            const mappedReleases = srv.srv.Releases.Releases.map((release) => {
                release.srv = srv;
                return release;
            });

            releases = releases.concat(mappedReleases)
        }

        releases.sort((a, b) => {
            return b.Date - a.Date;
        });

        return releases;
    }

    getOwners() {
        if (!this._owners) {
            this._owners = this.getServices()
                .map(x => x.getOwner())
                .filter((x, index, self) => !!x && self.indexOf(x) === index)
        }

        return this._owners;
    }

    async getPerOwnerReleaseStats(year) {
        const libs = [...this.getOwnerLibraries()];
        libs.push(this.getOwnerlessLibrary());

        const stats = {};
        for (const lib of libs) {
            await lib.getReleasesOverview().ready(false);
            stats[lib.shortName] = lib.getReleasesOverview().getStats(year)
        }

        const libStats = [
            median(Object.values(stats).map(x => x[0])),
            avg(Object.values(stats).map(x => x[1])),
            p100(Object.values(stats).map(x => x[2])),
        ];

        return [libStats, stats]
    }

    getOwnerLibraries() {
        if (this._ownerLibraries === undefined) {
            const lib = this.isFiltered() ? this.parentLib : this;

            const preFilter = this.filter ? x => this.filter(x) : x => true;

            if (!this.details.owners || this.details.owners.length === 0) {
                this._ownerLibraries = this.getOwners().map((owner, i) => {
                    //TODO slugify owner
                    return new FilteredLibrary(lib, lib.id + "_owner_" + owner, "Owner: " + owner, owner, x => {
                        return preFilter(x) && x.getOwner() && x.getOwner() === owner;
                    });
                })
            } else {
                this._ownerLibraries = this.details.owners.map((owner, i) => {
                    //TODO slugify owner
                    return new FilteredLibrary(lib, lib.id + "_owner_" + owner, "Owner: " + owner, owner, x => {
                        return preFilter(x) && x.getOwner() && x.getOwner() === owner;
                    });
                })
            }
        }

        return this._ownerLibraries;
    }

    getOwnerLibrary(owner) {
        return [...this.getOwnerLibraries(), this.getOwnerlessLibrary()].find(x => x.shortName === owner)
    }

    getCodebaseSize() {
        return this.getServices().reduce((acc, service) => {
            return acc + service.getCodebaseSize()
        }, 0)
    }

    getContexts() {
        return this.getServices().flatMap(x => x.getContexts()).filter(unique);
    }

    getOwnerlessLibrary() {
        if (!this._ownerlessLib) {
            const parent = this.isFiltered() ? this.parentLib : this;
            const preFilter = this.filter ? x => this.filter(x) : x => true;

            this._ownerlessLib = new FilteredLibrary(parent, parent.id + "_ownerless", "Owner: [Owner is not defined]", "[Owner is not defined]", x => {
                return preFilter(x) && !x.getOwner();
            });

            this._ownerlessLib.isOwnerless = true; //so far used only in Owners/index.js

        }

        return this._ownerlessLib;
    }

    getContextLibraries() {
        const lib = this;
        if (!this.details.contexts || this.details.contexts.length === 0) {
            return [];
        }
        return this.details.contexts.map((ctx, i) => {
            //TODO slugify ctx
            const c = new FilteredLibrary(lib, lib.id + "_ctx_" + ctx, "Context: " + ctx, ctx, x => {
                return x.getContexts() && x.getContexts().indexOf(ctx) !== -1;
            });
            c.shortName = ctx;
            return c;
        })
    }

    link() {
        return "/" + this.org.id + "/" + this.id;
    }

    linkToRegistry() {
        return this.link() + "/registry";
    }

    linkToBigPicture() {
        return this.link() + "/bigpicture";
    }

    linkToPulse() {
        return this.link() + "/pulse"
    }

    linkToRiskRadar() {
        return this.link() + "/riskradar"
    }

    linkToRiskRadarRiskType(type) {
        return this.linkToRiskRadar() + "/type/" + type
    }

    linkToHistory() {
        return this.linkToPulse() + "/history"
    }

    linkToProjectsActivity() {
        return this.linkToPulse() + "/activity"
    }

    linkToReleases(year, week) {
        let link = this.linkToPulse() + "/releases";
        if (year) {
            link += "/" + year;
        }
        if (week) {
            link += "/" + week;
        }
        return link;
    }

    linkToUserProfile() {
        return this.link() + "/profile";
    }

    linkToOwners() {
        return this.link() + "/team/owner";
    }

    linkToTeam() {
        return this.link() + "/team";
    }

    linkToContributorsOverview() {
        return this.link() + "/team/overview";
    }

    linkToTeamHistory() {
        return this.linkToTeam() + "/history";
    }

    linkToTech(tech) {
        const link = this.link() + "/tech";

        if (tech) {
            return link + "/" + tech;
        }

        return link;
    }

    isFiltered() {
        return false;
    }
}

export class FilteredLibrary extends Library {

    isLoaded = false;

    constructor(parentLib, id, name, shortName, filter) {
        super(parentLib.org, id, name, "", parentLib.loader);
        this.parentLib = parentLib;
        this.filter = filter;
        this.shortName = shortName;
    }

    getIdForDataRequest() {
        return this.parentLib.id;
    }

    isFiltered() {
        return true;
    }

    linkToOwnerProfile() {
        return this.parentLib.linkToTeam() + "/owner/" + this.shortName;
    }

    async ready(ignoreIsLoaded = true) {
        if (this.isLoaded && !ignoreIsLoaded) {
            return;
        }

        if (!this.parentLib.isLoaded) {
            await this.parentLib.ready();
        }

        this.services = this.parentLib.getServices().filter(this.filter).map(s => {
            return new Service(s.id, s.srv, this, s.loader);
        });

        this.techradar = new TechRadar(this, this.parentLib.lib.TechRadarOverview, this.parentLib.getTechRadar());

        this.isLoaded = true;
    }
}