export const TECH_ADOPT = "ADOPT";
export const TECH_TRIAL = "TRIAL";
export const TECH_ASSESS = "ASSESS";
export const TECH_HOLD = "HOLD";
export const TECH_ALIEN = "ALIEN";

export default class Tech {
    lib = undefined;
    name = "";
    applied = {};

    constructor(lib, name, currentStatusEntry, history, raw) {
        this.lib = lib;
        this.name = name;
        this.currentStatusEntry = currentStatusEntry;
        this.history = history;
        this.raw = raw;
    }

    isNew() {
        if (this.isInTechRadar() && this.getStatusDate()) {
            const n = new Date();

            n.setMonth(n.getMonth() - 3);

            return new Date(this.getStatusDate() * 1000) > n;
        }

        return false
    }

    is(name) {
        const lowercaseName = name.toLowerCase();

        if (this.name.toLowerCase() === lowercaseName) {
            return true
        }

        if (this.isInTechRadar()) {
            return this.currentStatusEntry.getAlias().find(x => x.toLowerCase() === lowercaseName) !== undefined;
        }

        return false;
    }

    isInTechRadar() {
        return !!this.currentStatusEntry;
    }

    getTags() {
        if (this.isInTechRadar()) {
            return this.currentStatusEntry.getTags();
        }

        return [];
    }

    getContributorsStats() {
        if (!this._contributorStats) {
            this._contributorStats = [];
            for (let contributor of this.lib.getContributors()) {
                const loc = contributor.getTechLOCContribution(this.getName());

                if (loc === 0) {
                    continue;
                }

                this._contributorStats.push({
                    contributor: contributor,
                    loc: loc
                });
            }

            this._contributorStats.sort((a, b) => {
                return a.loc > b.loc ? -1 : 1;
            })
        }

        return this._contributorStats;
    }

    hasTags(searchTags) {
        if (searchTags.length === 0) {
            return true;
        }

        return this.getTags().filter(x => searchTags.includes(x.toLowerCase())).length !== 0;
    }

    getName() {
        return this.name;
    }

    addApplication(srv, application) {
        this.applied[srv.id] = new AppliedTech(this, srv, application);
    }

    getState() {
        if (!this.isInTechRadar()) {
            switch (true) {
                case this.lib.getTechRadar().isDedicatedRadar():
                    return TECH_ALIEN;
                case this.getApplications().length > 2:
                    return TECH_ADOPT;
                case this.getApplications().length > 1:
                    return TECH_TRIAL;
                case this.getApplications().length === 1:
                    return TECH_ASSESS;
                default:
                    return TECH_HOLD;
            }
        }

        return this.currentStatusEntry.getStatus();
    }

    getAlias() {
        if (this.isInTechRadar()) {
            return this.currentStatusEntry.getAlias();
        }

        return [];
    }

    getRepositoryLink() {
        if (this.isInTechRadar()) {
            return this.lib.getTechRadar().getRepositoryLink() + "/blob/master/" + this.raw.DefinedIn;
        }
    }

    getDescription() {
        if (this.isInTechRadar()) {
            return this.currentStatusEntry.getDescription();
        }

        return undefined;
    }

    getStatusDate() {
        if (this.isInTechRadar()) {
            return this.currentStatusEntry.getDate();
        }

        return undefined;
    }

    getApplications() {
        return Object.values(this.applied);
    }

    getHistory() {
        return this.history || [];
    }

    link() {
        return this.lib.linkToTech(this.getName());
    }
}

export class TechRadarStatusEntry {
    constructor(status, date, description, alias, tags) {
        this.status = status;
        this.date = date;
        this.description = description;
        this.alias = alias;
        this.tags = tags;
    }

    getStatus() {
        return this.status;
    }

    getDate() {
        return this.date;
    }

    getDescription() {
        return this.description;
    }

    getAlias() {
        if (!!this.alias) {
            return this.alias;
        }
        return [];
    }

    getTags() {
        if (!!this.tags) {
            return this.tags;
        }
        return [];
    }
}

export class AppliedTech {
    tech = undefined;
    srv = undefined;
    application = "";

    constructor(tech, srv, application) {
        this.tech = tech;
        this.srv = srv;
        this.application = application;
    }

    getName() {
        return this.getTech().getName();
    }

    getTech() {
        return this.tech;
    }

    getService() {
        return this.srv;
    }

    getApplication() {
        return this.application;
    }
}