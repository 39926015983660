import Organisation from "./organisation";

export default class User {

    isLoaded = false;
    demoOrg = false;

    constructor(adminURL, loader, auth0User) {
        this.adminURL = adminURL;
        this.loader = loader;
        this.auth0User = auth0User;
    }

    async ready() {
        const orgs = await this.loader.get("/v1/orgs");
        this.orgs = {};
        orgs.data.forEach((org) => {
            this.orgs[org.Slug] = this.newOrg(org)
        });
        this.isLoaded = true;
    }

    newOrg(data) {
        return new Organisation(this.adminURL, data, this.loader);
    }

    getOrgs() {
        return this.orgs;
    }

    isAdmin(orgSlug) {
        return this.getOrg(orgSlug).data.IsAdmin;
    }

    getOrg(slug) {
        if (slug === "demo") {
            if (!this.demoOrg) {
                this.demoOrg = new Organisation(this.adminURL, {Name:"DemoOrg", Slug:"demo"}, this.loader);
            }
            
            return this.demoOrg;
        }
        return this.orgs[slug];
    }

    getEmail() {
        return this.auth0User.email;
    }
}