import React from "react";

export default function AppMain({children}) {
    return <div className="app-main">
        <div className="app-main__outer">
            <div className="app-main__inner">
                {children}
            </div>
        </div>
    </div>;
}