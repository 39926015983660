import React from 'react';

class PluginManager {
    events = null

    constructor() {
        this.plugins = {};
        this.events = {};
    }

    deregisterAll() {
        this.plugins = {};
        this.deregisterEvents();
    }

    deregisterEvents() {
        this.events = {};
    }

    registerPlugins(org, plugins) {
        plugins.forEach(plugin => this.registerPlugin(org, plugin))
    }

    registerPlugin(org, {type, registerOrgEventHandler, registerLibEventHandler}) {
        if (typeof registerOrgEventHandler === "function") {
            registerOrgEventHandler(org, (event, handler) => {
                this.on(event, handler, org)
            });
        }

        this.plugins[type] = registerLibEventHandler;
    }

    initLibraryPlugins(loadedLib, configs) {
        Object.keys(this.plugins).forEach(type => {
            if (!configs || !configs[type]) {
                return;
            }

            this.plugins[type](configs[type], (event, handler, ctx) => {
                this.on(event, handler, Context(loadedLib, ctx))
            })
        })
    }

    on(event, component, context) {
        if (this.events[event] === undefined) {
            this.events[event] = [];
        }

        this.events[event].push([component, getContext(context)])
    }

    render(event, params, context) {
        const ctx = getContext(context);
        return <>
            {
                this.events[event] && this.events[event]
                    .filter(x => x[1] === ctx || x[1] === "*")
                    .map(x => x[0])
                    .map((component) => {
                        const ExComponent = component;
                        return <ExComponent {...params}/>
                    })
            }
        </>
    }
}

function getContext(obj) {
    if (typeof obj === "string") {
        return obj;
    }

    if (obj && obj.getPluginsContext && typeof obj.getPluginsContext === "function") {
        return obj.getPluginsContext()
    }

    return ""
}

export function Context(...obj) {
    const ctx = obj.map(x => getContext(x)).filter(x => !!x).join("|");

    return {
        getPluginsContext() {
            return ctx;
        }
    };
}

const pluginManager = new PluginManager();

export default function usePlugins() {
    return pluginManager
}
