import Risk, {RISK_SEVERITY_INFO} from "../risk";

export class ServiceIsSPOFRisk extends Risk {
    id = "IS_SPOF";
    title = "Service is a potential SPOF";
    severity = RISK_SEVERITY_INFO;
    explanation = [
        `High potential of becoming a cascading failure entry point for your application landscape`
    ];
    description = (service) => {
        const amt = service === undefined ? "> 4" : service.srv.DependentServices.length;
        return `${amt} other services hardly depend on this service. 
                    This introduces <a href="https://en.wikipedia.org/wiki/Single_point_of_failure">SPOF</a> into an entire application landscape.`
    };

    mitigation = [
        `Consider switching to async/soft dependency for dependent services`,
        `Consider introducing “fragility” mitigation patterns in the dependent services`
    ];
    detailsLink = "/docs/risk-glossary/#service-is-a-potential-spof";

    detectFunc = srv => srv.srv.DependentServices.length > 4

}