import React, {useMemo, useState} from 'react';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import cx from 'classnames';
import {useHistory} from 'react-router-dom';

import {Typeahead} from 'react-bootstrap-typeahead';

function SearchBox({library}) {
    const [active, setActive] = useState(false);
    const history = useHistory();
    const searchItems = useMemo(() => {
        let items = [];

        if (library) {
            items = items.concat(library.getServices().map(x => {
                return {
                    value: "Service: " + x.getName(),
                    link: x.linkToRegistryOverview()
                }
            }));

            items = items.concat(library.getContributors().map(x => {
                return {
                    value: "Contributor: " + x.Login,
                    link: x.linkToProfile()
                }
            }));

            items = items.concat(library.getOwnerLibraries().map(x => {
                return {
                    value: "Owner: " + x.shortName,
                    link: library.linkToOwners() + "/" + x.shortName
                }
            }));

            const techs = library.getTechRadar().getTechNames();

            items = items.concat(techs.map(x => {
                return {
                    value: "Tech: " + x,
                    link: library.linkToTech(x)
                }
            }));
        }

        return items;
    }, [library]);

    if (!library) {
        return null;
    }

    return (
        <>
            <div className={cx("search-wrapper", {
                'active': active
            })}>
                <div className="input-holder" style={{overflow: "visible"}}>
                    {
                        active && <Typeahead
                            id="search"
                            labelKey="value"
                            autoFocus={true}
                            maxResults={15}
                            multiple={false}
                            onChange={(selected) => {
                                history.push(selected[0].link);
                                setActive(false);
                            }}
                            options={searchItems}
                            placeholder="Type to search"
                        />
                    }

                    {!active && <button onClick={() => setActive(!active)} className="search-icon"><span/></button>}
                </div>
                <button onClick={() => setActive(!active)} className="close"/>
            </div>
        </>
    )
}

export default SearchBox;