import {NavItem, NavLink} from "reactstrap";
import {Link, NavLink as NavLinkRR, Route} from "react-router-dom";
import React from "react";

const descriptor = {
    type: "PerspectiveIframeTab",
    //Executed during org loading
    registerOrgEventHandler: (org, registerEventHandler) => {},

    //executed during library loading
    registerLibEventHandler: (config, registerEventHandler) => {
        if (!config.Tabs) {
            return
        }

        config.Tabs.forEach(tabConfig => {
            registerEventHandler("LibraryRoute:render", Iframe(tabConfig.route, tabConfig.source))
            registerEventHandler("TopNavBar:render", PerspectiveIframeTab(tabConfig.name, tabConfig.icon, tabConfig.route))
            registerEventHandler("TopNavBar:render:Dots", PerspectiveIframeTabDots(tabConfig.name, tabConfig.icon, tabConfig.route))
        })
    }
}

export default descriptor;

function Iframe(route, source) {
    return () => {
        return <Route path={"/:orgSlug/:libraryId/" + route} render={() => {
            return <div className="app-main">
                <div className="app-main__outer">
                    <div className="app-main__inner" style={{padding: 0}}>
                        <iframe src={source} title={source} style={{
                            width: '100vw',
                            height: '95vh',
                            border: 0
                        }}/>
                    </div>
                </div>
            </div>
        }}/>
    }
}

function PerspectiveIframeTab(name, icon, route) {
    return ({library}) => {
        return <NavItem>
            <NavLink tag={NavLinkRR} to={library.link() + "/" + route} className={"nav-link"}>
                {
                    icon && <>
                        <img src={icon} width={20} alt="" />
                        &nbsp;
                    </>
                }
                {name}
            </NavLink>
        </NavItem>
    }
}

function PerspectiveIframeTabDots(name, icon, route) {
    return ({library, toggleMobileTopNavi}) => {
        return <Link to={library.link() + "/" + route} onClick={toggleMobileTopNavi}>
            <div className="icon-wrapper icon-wrapper-alt rounded-circle">
                <div className="icon-wrapper-bg bg-primary"/>
                {
                    icon && <>
                        <img src={icon} width={20} className={"m-auto"} alt="" />
                        &nbsp;
                    </>
                }
                {
                    !icon && <>
                        {name}
                    </>
                }
            </div>
        </Link>
    }
}