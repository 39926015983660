import Risk, {RISK_SEVERITY_LOW} from "../risk";

const DaysThreshold = 180;
const CriticalDaysThreshold = 360; // there is a separate risk for that

export class ServiceHasNoRecentAuditRisk extends Risk {
    id = "NO_RECENT_AUDIT";
    title = "Service audit is older than " + DaysThreshold + " days";
    severity = RISK_SEVERITY_LOW;
    explanation = [
        `Documentation tends to become outdated with time. In order to keep it up-to-date regular reviews are necessary.
        Last review was done more than `+DaysThreshold+` days ago and it is recommended to schedule a new audit session`
    ];
    description = (service) => {
        const n = service === undefined ? DaysThreshold : service.getDaysSinceLastAudit();
        return `Service had no audit in the last <b>${n}</b> days. <br/>
                    There is a risk of outdated documentation.`
    };

    mitigation = [
        `Schedule <a href="https://github.com/Service360/ServiceBoilerplate/tree/master/docs/audit">service audit</a>`
    ];
    detailsLink = "/docs/risk-detection/#no-recent-audit";

    detectFunc = srv => {
        return srv.getDaysSinceLastAudit() > DaysThreshold && srv.getDaysSinceLastAudit() < CriticalDaysThreshold;
    }
}