import Risk, {RISK_SEVERITY_HIGH} from "../risk";

export class ServiceDependencyOnDeprecatedRisk extends Risk {
    id = "DEPENDENCY_ON_DEPRECATED";
    title = "Service depends on the deprecated service";
    severity = RISK_SEVERITY_HIGH;
    explanation = [
        `Deprecated service might stop functioning after deprecation period and will cause an outage of your service`
    ];
    description = (service) => {
        const descr = `Service depends on the deprecated services`;

        if (!service) {
            return descr;
        }

        return descr + `: <ul><li>`+getDeprecatedDeps(service)
            .map(x => `<a href="`+x.linkToRegistryOverview()+`">`+x.getName()+`</a>`)
            .join("</li><li>")+ `</li></ul>`
    };

    mitigation = [
        `Replace deprecated service with its alternative`,
        `Retire/deprecate the service`,
        `Consider un-deprecation of the dependency service`
    ];
    detailsLink = "/docs/risk-glossary/#service-depends-on-the-deprecated-service";
    detectFunc = srv => {
        if (!srv.hasDependencies()) {
            return false;
        }

        return getDeprecatedDeps(srv).length !== 0;
    }
}


function getDeprecatedDeps(srv) {
    return Object.keys(srv.srv.PlantUML.Nodes).filter(name => {
        if (name === srv.getName()) {
            return false;
        }

        const depSrv = srv.lib.getService(name);
        if (depSrv) {
            return depSrv.isDeprecated()
        }

        return false;
    }).map(x => srv.lib.getService(x));
}
