import Risk, {RISK_SEVERITY_CRITICAL} from "../risk";

export class ServiceDependencyOnRetiredRisk extends Risk {
    id = "DEPENDENCY_ON_RETIRED";
    title = "Service depends on the RETIRED service";
    severity = RISK_SEVERITY_CRITICAL;
    explanation = [
        `Retired service might stop functioning any moment (if not already) and cause an outage of your service`
    ];
    description = (service) => {
        const descr = `Service depends on RETIRED service. Retired services are not supposed to be in use and their 
        functioning is not guaranteed`;

        if (!service) {
            return descr;
        }

        return descr + `: <ul><li>`+getRetiredDeps(service)
            .map(x => `<a href="`+x.linkToRegistryOverview()+`">`+x.getName()+`</a>`)
            .join("</li><li>")+ `</li></ul>`
    };

    mitigation = [
        `Replace retired service with its alternative`,
        `Retire this service`,
        `Restore retired dependency service`,
        `Update dependency documentation, if it is outdated`
    ];
    detailsLink = "/docs/risk-glossary/#dependency-on-the-retired-service";
    detectFunc = srv => {
        if (!srv.hasDependencies()) {
            return false;
        }

        return getRetiredDeps(srv).length !== 0
    }
}

function getRetiredDeps(srv) {
    return Object.keys(srv.srv.PlantUML.Nodes).filter(name => {
        if (name === srv.getName()) {
            return false;
        }

        const depSrv = srv.lib.getService(name);
        if (depSrv) {
            return depSrv.isRetired()
        }

        return false;
    }).map(x => srv.lib.getService(x));
}