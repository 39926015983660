export default class ReleasesByWeek {

    isLoaded = false;
    overview = {
        Releases: []
    };

    constructor(lib, year, week) {
        this.lib = lib;
        this.year = year;
        this.week = week;
    }

    async ready() {
        const d = await this.lib.loader.get(this.lib.getDataURI() + "/releasesByWeek/" + this.year + "/" + this.week);
        this.overview = d.data;

        //Filtered libraries support
        this.overview.Releases = Object.keys(this.overview.Releases).map(repo => {
            const srv = this.lib.getServiceByName(repo);
            if (!srv) {
                return null;
            }
            return this.overview.Releases[repo].map(release => {
                release.srv = srv;
                return release;
            })
        });
        this.overview.Releases = Object.values(this.overview.Releases)
            .flat()
            .filter(x => x !== null)
            .sort((a, b) => a.Date > b.Date ? -1 : 1);
        this.isLoaded = true;
    }

    getReleases() {
        return this.overview.Releases;
    }
}