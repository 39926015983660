import ReleasesByWeek from "./releasesByWeek";
import {avg, median, p100, sum} from "../utils/math";
import {getWeekNumber} from "../utils/date";

export default class ReleasesOverview {

    isLoaded = false;
    overview = {};
    byWeek = {};

    constructor(lib) {
        this.lib = lib
    }

    async ready(ignoreLoaded = true) {
        if (!ignoreLoaded && this.isLoaded) {
            return;
        }

        let d;

        await this.lib.ready(false);

        if (!this.lib.isFiltered()) {
            d = await this.lib.loader.get(this.lib.getDataURI() + "/releasesByWeek/");
        } else {
            await this.lib.getMainLibrary().getReleasesOverview().ready(false);
            d = JSON.parse(JSON.stringify({
                data: {
                    Releases: this.lib.getMainLibrary().getReleasesOverview().overview.AllReleases
                }
            }));
        }

        this.overview = d.data;

        this.overview.AllReleases = JSON.parse(JSON.stringify(this.overview.Releases));

        // Filtered libraries support
        Object.keys(this.overview.Releases).forEach(year => {
            Object.keys(this.overview.Releases[year]).forEach(week => {
                Object.keys(this.overview.Releases[year][week]).forEach(service => {
                    if (!this.lib.getServiceByName(service)) {
                        delete this.overview.Releases[year][week][service];
                    }
                });

                if (Object.values(this.overview.Releases[year][week]).length === 0) {
                    delete (this.overview.Releases[year][week]);
                }
            });

            if (Object.values(this.overview.Releases[year]).length === 0) {
                delete (this.overview.Releases[year]);
            }
        });

        this.isLoaded = true;
    }

    getReleases() {
        return this.overview.Releases;
    }

    getYearlyStats() {
        let values = {};

        const releases = this.getReleases();

        for (let year in releases) {
            values[year] = this.getStats(year);
        }

        return values;
    }

    getStats(year, week) {
        let values = [];

        const releases = this.getReleases();

        if (year && week) {
            if (releases[year] && releases[year][week]) {
                values = Object.values(releases[year][week]).reduce((acc, x) => acc + x, 0);
            }
        } else if (year) {
            if (releases[year]) {
                values = Object.values(fillMissingWeeks(releases[year], year))
                    .flatMap(x => Object.values(x).reduce((acc, x) => acc + x, 0))
            }
        } else {
            values = Object.keys(releases)
                .flatMap(x => Object.values(fillMissingWeeks(releases[x], x)))
                .flatMap(x => Object.values(x).reduce((acc, x) => acc + x, 0))
        }

        return [median(values), avg(values), p100(values), sum(values)];
    }

    getReleasesByWeek(year, week) {
        if (!this.byWeek[year]) {
            this.byWeek[year] = {};
        }

        if (!this.byWeek[year][week]) {
            this.byWeek[year][week] = new ReleasesByWeek(this.lib, year, week);
        }

        return this.byWeek[year][week];
    }
}

function fillMissingWeeks(yearlyOverview, year) {
    const [currentYear, currentWeek] = getWeekNumber(new Date());
    const tillWeek = currentYear === year ? currentWeek : 53;
    const newOverview = {};

    for (let i = 1; i <= tillWeek; i++) {
        if (yearlyOverview[i]) {
            newOverview[i] = yearlyOverview[i];
        } else {
            newOverview[i] = {"filler": 0}
        }
    }

    return newOverview;
}