import Risk, {RISK_SEVERITY_HIGH} from "../risk";

export class ServiceHasNoActiveSMERisk extends Risk {
    id = "NO_ACTIVE_SME";
    title = "Service has no active SME";
    severity = RISK_SEVERITY_HIGH;
    explanation = [
        `Increased probability of loss of knowledge/<a href="https://www.instigatorblog.com/the-half-life-of-trust-reputation-and-social-capital/2009/11/19/">team trust</a> in the app and slow response on the any occurring incident. `
    ];
    description = (service) => {
        return `There is no active SMEs left with the hands-on knowledge of this service.`
    };
    mitigation = [
        `Schedule knowledge transfer/audit session`,
        `Retire/deprecate the service`
    ];
    detailsLink = "/docs/risk-glossary/#service-has-no-active-sme";

    detectFunc = srv => srv.getSMEs().find(x => isActive(srv, x)) === undefined
}

function isActive(srv, contributor) {
    const c = srv.lib.getContributor(contributor.Login);

    if (c) {
        return c.isActive();
    }

    return contributor.LastWeeks.Commits > 0
}