import Risk, {RISK_SEVERITY_LOW} from "../risk";
import {TECH_HOLD} from "../../domain/tech";

export class ServiceUsesHoldTechnologiesRisk extends Risk {
    id = "HOLD_TECH_IN_USE";
    title = "Service uses HOLD technologies";
    severity = RISK_SEVERITY_LOW;
    explanation = [
        `Technologies marked as HOLD on TechRadar for a reason. Usage of those technologies for the production
        services is allowed only for the legacy applications. Usage of HOLD technologies imposes different risks to an 
        organization`
    ];
    description = (service) => {
        let descr = `Service uses technologies marked as HOLD in TechRadar`;

        if (service) {
            descr += ": <ul><li>" + getHoldTechs(service).map(x => x.getName()).join("</li><li>") + "</li></ul>"
        }

        return descr
    };
    mitigation = [
        `Consider replacing HOLD technologies with the ADOPT/TRIAL alternatives`
    ];
    detailsLink = "/docs/risk-glossary/#service-uses-hold-technologies";

    detectFunc = srv => {
        return getHoldTechs(srv).length !== 0
    }
}

function getHoldTechs(srv) {
    if (!srv.lib.getTechRadar().isDedicatedRadar()) {
        return [];
    }

    return Object.keys(srv.getTech() || {})
        .map(x => srv.lib.getTechRadar().getTech(x))
        .filter(x => x.getState() === TECH_HOLD)
}