import {daysSince, weeksSince} from "./date";

export function getSMECoef(daysSince) {
    switch (true) {
        case daysSince <= 14:
            return 1;
        case daysSince <= 30:
            return 2;
        case daysSince <= 60:
            return 3;
        case daysSince <= 180:
            return 5;
        case daysSince <= 360:
            return 8;
        case daysSince <= 540:
            return 13;
        case daysSince <= 720:
            return 21;
        case daysSince <= 720 * 1.5:
            return 50;
        case daysSince <= 720 * 3:
            return 100;
        default:
            return 200;
    }
}

// export function score(c, type="Total") {
//     const contribution = Math.abs(c[type].Additions) + Math.abs(c[type].Deletions);
//     const isDisproportional = Math.abs( contribution / c[type].Commits ) > 1000;
//
//     const locWeight = isDisproportional
//         ? contribution / 400
//         : contribution / 100;
//
//     const coef = getSMECoef(daysSince(c.LastContribution));
//
//     return (c[type].Commits + locWeight) / (coef * coef);
// }

export function contribution(c, type="Total") {
    return Math.abs(c[type].NLOC);
}

export function performance(c, type="Total") {
    const weeksPassed = type === "Total" ? weeksSince(c.FirstContribution) : 4;

    return Math.round(contribution(c, type) / weeksPassed);
}

export function score(c, type="Total") {
    const coef = getSMECoef(daysSince(c.LastContribution));

    return (contribution(c, type)) / (coef * coef);
}
