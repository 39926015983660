import React, {useEffect, useState} from 'react';
import {Loader} from "react-loaders";
import BlockUi from "react-block-ui";
import SweetAlert from "sweetalert-react";

export default function Loaded({item, message, children, failOn404 = true, showContainer=true, onSuccess = () => {}}) {
    const [loaded, setLoaded] = useState(!!item.isLoaded);
    const [alertWasTriggered, setAlertWasTriggered] = useState(false);
    const [loadError, setLoadError] = useState(false);

    // console.log('requested loaded', item, loaded);

    useEffect(() => {
        if (!loaded) {
            // console.log('actually loading', item);
            item.ready()
                .then(() => {
                    if (onSuccess) {
                        onSuccess(item);
                    }
                })
                .catch((e) => {
                    if (!failOn404 && e.response.status === 404) {
                        return;
                    }
                    console.log(e);
                    setLoadError(e);
                    setAlertWasTriggered(true);
                })
                .finally(() => {
                    setLoaded(true);
                });
        }
    }, [loaded, item]);

    if (!loaded) {
        return <BlockUi tag="div" blocking={true}
                        message={message}
                        loader={<Loader active type="ball-pulse"/>}>
            {showContainer && <div className="app-container app-theme-white"/>}
        </BlockUi>;
    }

    return <>
        <SweetAlert
            title="Error"
            confirmButtonColor=""
            show={loadError !== false}
            text={loadError ? loadError.message + "\n\n" + (loadError.response ? loadError.response.data : "") : ""}
            confirmButtonText="Okay"
            onConfirm={() => {
                setLoadError(false);
            }}
            type="error"/>
        {!loadError && !alertWasTriggered && children}
    </>
}