// sort array ascending
const asc = arr => arr.sort((a, b) => a - b);

export const sum = arr => arr.reduce((a, b) => a + b, 0);

const mean = arr => sum(arr) / arr.length;

// sample standard deviation
export const std = (arr) => {
    const mu = mean(arr);
    const diffArr = arr.map(a => (a - mu) ** 2);
    return Math.sqrt(sum(diffArr) / (arr.length - 1));
};

export const percentile = (arr, q) => {
    const sorted = asc(arr);
    const pos = (sorted.length - 1) * q;
    const base = Math.floor(pos);
    const rest = pos - base;
    if (sorted[base + 1] !== undefined) {
        return sorted[base] + rest * (sorted[base + 1] - sorted[base]);
    } else {
        return sorted[base];
    }
};

export const p90 = arr => percentile(arr, .90);
export const p95 = arr => percentile(arr, .95);
export const p99 = arr => percentile(arr, .99);
export const p100 = arr => percentile(arr, 1);
export const avg = mean;

export const median = arr => percentile(arr, .50);

window.m360 = {
    percentile: percentile,
    mean: mean,
    sum: sum,
    p99: p99,
    p95: p95,
    p90: p90,
    std: std,
};