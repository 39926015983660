import Risk, {RISK_SEVERITY_MEDIUM} from "../risk";

export class ServiceDependencyOnUnknownRisk extends Risk {
    id = "DEPENDENCY_ON_UNKNOWN";
    title = "Service depends on the unknown service";
    severity = RISK_SEVERITY_MEDIUM;
    explanation = [
        `Blind spots in the architecture landscape might lead to suboptimal solutions on the landscape evolution path`,
        `Missing external services might lead to missed learning opportunities and wasted reimplementation/reintegration time`
    ];
    description = (service) => {
        const descr = `Service depends on the unknown service. Unknown services are blind spots in the architecture landscape`;

        if (!service) {
            return descr;
        }

        return descr + `: <ul><li>`+getUnknownDeps(service)
            .join("</li><li>")+ `</li></ul>`
    };

    mitigation = [
        `Add unknown services to the <a href="/docs/external-services/">ExternalServices repository</a>`,
        `Remove dependency on the unknown service`
    ];
    detailsLink = "/docs/risk-glossary/#service-depends-on-the-unknown-service";
    detectFunc = srv => {
        if (!srv.hasDependencies()) {
            return false;
        }

        return getUnknownDeps(srv).length !== 0
    }
}

function getUnknownDeps(srv) {
    return Object.keys(srv.srv.PlantUML.Nodes)
        .filter(name => {
            return srv.srv.PlantUML.Nodes[name].Type !== 'actor'
        })
        .filter(name => {
        if (name === srv.getName()) {
            return false;
        }

        return !srv.lib.getService(name)
    });
}