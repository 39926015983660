import {percentile} from "./math";

function getTechCount(service) {
    return service.getTech() ? Object.values(service.getTech()).length : 0;
}

function getDependenciesCount(service) {
    return service.getDependencies().length;
}

const cache = {
    "loc": {},
    "tech": {},
    "deps": {},
    "cmplx": {},
};

export function getLOCScale(library, cached = true) {
    return getScaleByFunc(library, cached, x => x.getCodebaseSize(), "loc");
}

export function getTechStackScale(library, cached = true) {
    return getScaleByFunc(library, cached, getTechCount, "tech");
}

export function getDependenciesScale(library, cached = true) {
    return getScaleByFunc(library, cached, getDependenciesCount, "deps");
}

export function getComplexityScale(library, cached = true) {
    return getScaleByFunc(library, cached, x => getServiceComplexityScore(library, x)[0], "cmplx");
}

function getScaleByFunc(library, cached = true, mapFunc, index) {
    if (!cached || cache[index][library.id] === undefined) {
        const cmplx = library.getServices()
            .filter(x => !x.isRetired())
            .map(x => mapFunc(x)).filter(x => !!x);

        cache[index][library.id] = [
            percentile(cmplx, 0.25),
            percentile(cmplx, 0.5),
            percentile(cmplx, 0.75),
            percentile(cmplx, 0.9),
        ];
    }

    return cache[index][library.id]
}

function getScore(scale, value) {
    for (let i in scale) {
        if (value <= scale[i]) {
            return parseInt(i) + 1;
        }
    }

    return scale.length + 1;
}

export function getServiceComplexityScore(library, service, cached = true) {
    const locScore = getScore(getLOCScale(library, cached), service.getCodebaseSize());
    const techScore = getScore(getTechStackScale(library, cached), getTechCount(service));
    const depsScore = getScore(getDependenciesScale(library, cached), getDependenciesCount(service)) * 2;

    return [locScore + techScore + depsScore, locScore, techScore, depsScore];
}

export function getServiceComplexity(library, service, cached = true) {
    const score = getScore(getComplexityScale(library, cached), getServiceComplexityScore(library, service)[0]);
    return score !== 1 && service.isInMaintenanceOnlyMode() ? score - 1 : score;
}

export function getFilteredLibraryComplexityScores(library) {
    const scores = [];

    for (let i = 1; i <= getComplexityScale(library.parentLib).length + 1; i++) {
        scores[i] = []
    }

    library.getServices().filter(x => !x.isRetired()).forEach(x => {
        const cplx = library.parentLib.getService(x.id).getComplexity();
        scores[cplx].push(x.getName())
    });

    const totalScore = scores
        .map((x, index, self) => x.length * complexityPoints(index))
        .reduce((acc, v) => acc + v, 0);

    return [scores, totalScore];
}

function complexityPoints(i) {
    switch (i) {
        case 1:
            return 1;
        case 2:
            return 2;
        case 3:
            return 5;
        case 4:
            return 8;
        case 5:
            return 13;
        default:
            throw new Error("not supported complexity index")
    }
}