import React, {useEffect, useState} from 'react';
import ErrorBoundary from "../ErrorBoundary";
import ReactMarkdown from "react-markdown";
import {Loader} from "react-loaders";
import BlockUi from "react-block-ui";

export default function Markdown(props) {
    return <ErrorBoundary fallback={<div>{props.source}</div>}>
        <ReactMarkdown
            {...props}
        />
    </ErrorBoundary>
}

export function ServiceDocImage(service, baseDocPath) {
    return function ({alt, title, src}) {
        const [content, setContent] = useState(false);

        useEffect(() => {
            if (src.indexOf("http") === 0) {
                setContent(src);
                return;
            }

            (async () => {
                const u = new URL(baseDocPath + src, "http://whatever");
                const img = await service.getDocs().getBinaryContent(u.pathname);

                switch(true) {
                    case src.endsWith(".jpg") || src.endsWith(".jpeg"):
                        setContent("data:image/jpeg;base64," + img);
                        break;
                    case src.endsWith(".png"):
                        setContent("data:image/png;base64," + img);
                        break;
                    default:
                        setContent("");
                }

            })(src)
        }, [src]);

        return <>
            {
                !content &&
                <BlockUi tag="div" blocking={true}
                         message={"loading"}
                         loader={<Loader active type="ball-pulse"/>}>
                    Loading image...
                </BlockUi>
            }
            {
                content &&
                <img style={{maxWidth: "100%"}} src={content} alt={alt} title={title}/>
            }
        </>
    }
}