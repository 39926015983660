import Risk, {RISK_SEVERITY_MEDIUM} from "../risk";

export class ServiceIsDeprecatedRisk extends Risk {
    id = "DEPRECATED";
    title = "Service is deprecated";
    severity = RISK_SEVERITY_MEDIUM;
    explanation = [
        `Deprecated service imposes a direct threat to a business due to a “deprecation” reason.`
    ];
    description = (service) => {
        return `Service is marked as Deprecated`
    };

    mitigation = [
        `Retire the service`
    ];
    detailsLink = "/docs/risk-glossary/#service-is-deprecated";
    detectFunc = srv => srv.isDeprecated()

}