import Library, {FilteredLibrary} from "./library";
import PerspectiveIFrameTab from "../plugins/PerspectiveIframeTab";
import OwnerIframeTab from "../plugins/OwnerIframeTab";
import Team from "./team";
import UserProfile, {LoggedInUserProfile} from "./userProfile";

export default class Organisation {

    _userProfiles;
    isLoaded = false;

    constructor(adminURL, data, loader) {
        this.adminURL = adminURL;
        this.data = data;
        this.loader = loader;

        //TODO: redo on getters
        this._userProfiles = {};
        this.name = data.Name;
        this.id = data.Slug;
        this.org = data.Overview;
    }

    async ready() {
        if (this.org === undefined) {
            const data = await this.loader.get("/v1/orgs/" + this.id);
            this.org = data.data;
        }

        this.libraries = this.org.libraries.map((lib) => {
            return new Library(this, lib.id, lib.name, lib, this.loader);
        });

        this.org.teams = this.org.teams || [];

        this.teams = this.org.teams.map(teamData => {
            return new Team(this, teamData);
        })

        this.org.registeredSoloContributors = this.org.registeredSoloContributors || []

        this.registeredSoloContributors = this.org.registeredSoloContributors.map(userData => {
            return new UserProfile(this, userData);
        })

        const filteredLibs = [];
        for (const lib of this.libraries) {
            filteredLibs.push(lib.getOwnerlessLibrary());
            lib.getOwnerLibraries().forEach(lib => {
                filteredLibs.push(lib)
            });
            lib.getContextLibraries().forEach(lib => {
                filteredLibs.push(lib)
            });
        }

        this.libraries = this.libraries.concat(filteredLibs);
        this.isLoaded = true;
    }

    getLibrary(id) {
        return this.libraries.find(lib => {
            return lib.id === id;
        });
    }

    getNonFilterLibraries() {
        return this.libraries
            .filter(x => !(x instanceof FilteredLibrary))
            .sort((a, b) => a.getName() > b.getName() ? 1 : -1)
    }

    getLoggedInUserProfile() {
        if (!this._loggedInUserProfile) {
            this._loggedInUserProfile = new LoggedInUserProfile(this);
        }

        return this._loggedInUserProfile;
    }

    getUserProfile(email) {
        if (!this._userProfiles[email]) {
            this._userProfiles[email] = new UserProfile(this, {EMail: email})
        }

        return this._userProfiles[email];
    }

    getUserProfilesForContributor(contributor) {
        const profiles = [
            ...this.registeredSoloContributors.filter(x => x.hasProfileOfAnyType(contributor.Login)),
            ...this.getTeams().map(x => [x.getLead(), ...x.getMembers()]).flat().filter(x => x.hasProfileOfAnyType(contributor.Login))
        ];

        // filter out non-unique values (user is a part of many teams/lead)
        const profileMap = {};
        profiles.forEach(x => {
            profileMap[x.getEmail()] = x;
        })

        return Object.values(profileMap);
    }

    getId() {
        return this.id;
    }

    getName() {
        return this.name;
    }

    linkToOverview() {
        return "/" + this.id;
    }

    linkToAdmin() {
        return this.adminURL + this.getId();
    }

    getTeams() {
        return this.teams;
    }

    getTeam(name) {
        return this.getTeams().find(x => x.getName() === name);
    }

    getPlugins() {
        return [
            PerspectiveIFrameTab,
            OwnerIframeTab,
        ]
    }

    getPluginsContext() {
        return "org#" + this.getId();
    }
}