export const ProfileTypeGithub = "Github";

export default class UserProfile {
    isLoaded = false;

    constructor(org, data) {
        this.org = org;
        this.setData(data);
    }

    async ready() {
        const data = await this.org.loader.get("/v1/orgs/" + this.org.getId() + "/users/" + this.getEmail());
        this.setData(data.data);
        this.isLoaded = true;
    }

    setData(data) {
        this.data = data;
        if (this.data.Profiles === null) {
            this.data.Profiles = {};
        }
    }

    getName() {
        return this.data.Name || this.getEmail();
    }

    getInfo() {
        return this.data.Info;
    }

    getTeams() {
        return this.data.Teams || [];
    }

    getContacts() {
        return this.data.Contacts || [];
    }

    getEmail() {
        return this.data.EMail;
    }

    getProfiles(type) {
        return type === undefined ? this.data.Profiles : (this.data.Profiles[type] || []);
    }

    hasProfileOfType(type, name) {
        return this.getProfiles(type).find(x => x === name) !== undefined
    }

    hasProfileOfAnyType(name) {
        return Object.values(this.getProfiles()).flat().find(x => x === name) !== undefined;
    }
}

export class LoggedInUserProfile {
    isLoaded = false;

    constructor(org, data) {
        this.org = org;
        this.setData(data);
    }

    async ready() {
        const data = await this.org.loader.get("/v1/orgs/" + this.org.getId() + "/profile");
        this.setData(data.data);
        this.isLoaded = true;
    }

    async save() {
        await this.org.loader.put("/v1/orgs/" + this.org.getId() + "/profile", this.data)
    }

    setData(data) {
        this.data = data;
        if (this.data && this.data.Profiles === null) {
            this.data.Profiles = {};
        }
    }

    getName() {
        return this.data.Name;
    }

    getInfo() {
        return this.data.Info;
    }

    getContacts() {
        return this.data.Contacts || [];
    }

    getEmail() {
        return this.data.EMail;
    }

    getProfiles(type) {
        return type === undefined ? this.data.Profiles : (this.data.Profiles[type] || []);
    }
}