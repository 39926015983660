import React from 'react';
import PageTitle from "../../App/components/PageTitle";
import {Nav, NavItem, NavLink} from "reactstrap";
import classnames from "classnames";
import {Link, Route, useRouteMatch} from "react-router-dom";
import ServiceTitle from "./ServiceTitle";
import usePlugins, {Context} from "../../../hooks/usePlugins";

export default function SectionSelector({service}) {
    const plugins = usePlugins();

    let section = "";

    let isOverview = useRouteMatch(service.linkToRegistryOverview()) !== null;
    let isDocs = useRouteMatch(service.linkToRegistryDocs()) !== null;
    let isDeps = useRouteMatch(service.linkToRegistryDependencies()) !== null;
    let isAPI = useRouteMatch(service.linkToRegistryApi()) !== null;
    let isReleases = useRouteMatch(service.linkToRegistryReleases()) !== null;
    let isContributors = useRouteMatch(service.linkToRegistryContributors()) !== null;
    let isHistory = useRouteMatch(service.linkToRegistryHistory()) !== null;

    switch (true) {
        case isOverview:
            section = "Overview";
            break;
        case isDocs:
            section = "Docs";
            break;
        case isDeps:
            section = "Dependencies";
            break;
        case isAPI:
            section = "API";
            break;
        case isReleases:
            section = "Releases";
            break;
        case isContributors:
            section = "Contributors";
            break;
        case isHistory:
            section = "History";
            break;
        default:
            break;
    }
    return <>
        <PageTitle heading={<ServiceTitle service={service} withLink={false}/>} subheading={section}>
            <Nav pills className={"mb-0"}>
                <NavButton to={service.linkToRegistryOverview()}>Overview</NavButton>
                {
                    service.hasDocs() &&
                    <NavButton to={service.linkToRegistryDocs()} className={"docs"}>Docs</NavButton>
                }
                {
                    service.hasDependencies() &&
                    <NavButton to={service.linkToRegistryDependencies()} className={"deps"}>Dependencies</NavButton>
                }
                {
                    service.hasApi() &&
                    <NavButton to={service.linkToRegistryApi()} className={"api"}>API</NavButton>
                }
                {
                    service.hasReleases() &&
                    <NavButton to={service.linkToRegistryReleases()} className={"releases"}>Releases</NavButton>
                }
                {
                    service.hasContributors() &&
                    <NavButton to={service.linkToRegistryContributors()}
                               className={"contributors"}>Contributors</NavButton>
                }
                {
                    service.hasContributors() &&
                    <NavButton to={service.linkToRegistryHistory()}
                               className={"history"}>History</NavButton>
                }
                {
                    plugins.render("ServiceRegistryTab:render", {service: service}, Context(service))
                }
            </Nav>
        </PageTitle>
    </>
}

export function NavButton({to, children, className, exact = false, pills=true, disabled=false}) {
    const path = typeof to === "object" ? to.pathname : to;
    return <Route
        path={path}
        exact={exact}
        children={({match}) => {
            const isActive = !!match;

            return (
                <NavItem>
                    <NavLink size="sm" outline color="alternate" tag={Link} to={path} disabled={disabled}
                             className={"btn-wide mr-1 ml-1 " + classnames({active: isActive, "btn-pill": pills}, className)}
                    >{children}</NavLink>
                </NavItem>
            );
        }}
    />
}