import {
    getRiskSeverityColor,
    RISK_SEVERITY_CRITICAL,
    RISK_SEVERITY_HIGH,
    RISK_SEVERITY_INFO,
    RISK_SEVERITY_LOW,
    RISK_SEVERITY_MEDIUM,
    RISK_SEVERITY_NONE
} from "./risk";
import {ServiceIsAbandonedRisk} from "./servicerisks/serviceIsAbandoned";
import {ServiceHasNoOwnerRisk} from "./servicerisks/serviceHasNoOwner";
import {ServiceIsFragileRisk} from "./servicerisks/serviceIsFragile";
import {ServiceHasNoPassportRisk} from "./servicerisks/serviceHasNoPassport";
import {ServiceHasNoRecentReleasesRisk} from "./servicerisks/serviceHasNoRecentReleases";
import {ServiceIsDeprecatedRisk} from "./servicerisks/serviceIsDeprecated";
import {ServiceHasNoDescriptionRisk} from "./servicerisks/serviceHasNoDescription";
import {ServiceIsSPOFRisk} from "./servicerisks/serviceIsSPOF";
import {ServiceHasNoDepsDocumentationRisk} from "./servicerisks/serviceHasNoDepsDocumentation";
import {ServiceHasNoActiveContributorsRisk} from "./servicerisks/serviceHasNoActiveContributors";
import {ServiceHasNoActiveSMERisk} from "./servicerisks/serviceHasNoActiveSME";
import {ServiceHasLowBusFactorRisk} from "./servicerisks/serviceHasLowBusFactor";
import {ServiceUsesHoldTechnologiesRisk} from "./servicerisks/serviceUsesHoldTechnologies";
import {ServiceUsesAlienTechnologiesRisk} from "./servicerisks/serviceUsesAlienTechnologies";
import {ServiceHasNoTechRisk} from "./servicerisks/serviceHasNoTech";
import {ServiceUsesTooManyNonADOPTTechnologiesRisk} from "./servicerisks/serviceUsesTooManyNonADOPTTechnologies";
import {ServiceDependencyOnRetiredRisk} from "./servicerisks/serviceDependencyOnRetired";
import {ServiceDependencyOnDeprecatedRisk} from "./servicerisks/serviceDependencyOnDeprecated";
import {ServiceDependencyOnUnknownRisk} from "./servicerisks/serviceDependencyOnUnknown";
import {ServiceHasNoHowToDocumentationRisk} from "./servicerisks/docsrisks/serviceHasNoHowToDocumentation";
import {ServiceHasNoReleaseDocumentationRisk} from "./servicerisks/docsrisks/serviceHasNoReleaseDocumentation";
import {ServiceHasNoMonitoringDocumentationRisk} from "./servicerisks/docsrisks/serviceHasNoMonitoringDocumentation";
import {ServiceHasNoDevelopmentDocumentationRisk} from "./servicerisks/docsrisks/serviceHasNoDevelopmentDocumentation";
import {ServiceHasNoPostMortemDocumentationRisk} from "./servicerisks/docsrisks/serviceHasNoPostMortemDocumentation";
import {ServiceHasNoDisasterRecoveryDocumentationRisk} from "./servicerisks/docsrisks/serviceHasNoDisasterRecoveryDocumentation";
import {ServiceHasNoStatusRisk} from "./servicerisks/serviceHasNoStatus";
import {ServiceDependencyOnAbandonedRisk} from "./servicerisks/serviceDependencyOnAbandoned";
import {ServiceHasNoRecentAuditRisk} from "./servicerisks/serviceHasNoRecentAudit";
import {ServiceAuditIsRequiredRisk} from "./servicerisks/serviceAuditIsRequired";

export default class RiskDetector {
    risks = {};

    constructor(risks) {
        this.risks = risks;
    }

    Risks() {
        return this.risks
    }

    GetRisk(name) {
        return this.risks.find(x => x.id === name);
    }

    ListRisks(services) {
        const oneService = !Array.isArray(services);
        if (oneService) {
            services = [services];
        }
        const risks = {};

        for (const srv of services) {
            risks[srv.getId()] = {
                "critical": [],
                "high": [],
                "medium": [],
                "low": [],
                "info": [],
            };

            for (const risk of this.Risks()) {
                if (srv.isRetired() || srv.isExternal()) {
                    continue;
                }

                if (risk.check(srv)) {
                    risks[srv.getId()][risk.severity].push(risk)
                }
            }
        }

        if (oneService) {
            return Object.values(risks)[0];
        }

        return risks;
    }

    ListRisksBySeverity(services) {
        const oneService = !Array.isArray(services);
        if (oneService) {
            services = [services];
        }
        const risks = {};
        risks[RISK_SEVERITY_CRITICAL] = [];
        risks[RISK_SEVERITY_HIGH] = [];
        risks[RISK_SEVERITY_MEDIUM] = [];
        risks[RISK_SEVERITY_LOW] = [];
        risks[RISK_SEVERITY_INFO] = [];
        risks[RISK_SEVERITY_NONE] = [];

        for (const srv of services) {
            let hasRisks = false;
            for (const risk of this.Risks()) {
                if (srv.isRetired() || srv.isExternal()) {
                    continue;
                }
                hasRisks = true;

                if (risk.check(srv)) {
                    risks[risk.severity].push([srv.getId(), risk])
                }
            }
            if (!hasRisks) {
                risks[RISK_SEVERITY_NONE].push([srv.getId()])
            }
        }

        if (oneService) {
            return Object.values(risks)[0];
        }

        return risks;
    }

    ListRisksByType(services) {
        const oneService = !Array.isArray(services);
        if (oneService) {
            services = [services];
        }
        const risks = {};

        for (const risk of this.Risks()) {
            const riskID = risk.id;

            risks[riskID] = [];

            for (const srv of services) {
                if (srv.isRetired() || srv.isExternal()) {
                    continue;
                }

                if (risk.check(srv)) {
                    risks[riskID].push(srv)
                }
            }

            if (risks[riskID].length === 0) {
                delete risks[riskID];
            }
        }

        return risks;
    }

    ServiceRiskLevel(service) {
        const risks = this.ListRisks(service);
        for (let severity of Object.keys(risks)) {
            if (risks[severity].length !== 0) {
                return severity;
            }
        }

        return RISK_SEVERITY_NONE;
    }

    GetServiceRiskLevelColor(service) {
        return getRiskSeverityColor(this.ServiceRiskLevel(service));
    }
}

export const riskDetector = new RiskDetector(
    [
        //Critical
        new ServiceIsAbandonedRisk(),
        new ServiceHasNoActiveContributorsRisk(),
        new ServiceDependencyOnRetiredRisk(),

        //High
        new ServiceHasNoOwnerRisk(),
        new ServiceDependencyOnAbandonedRisk(),
        new ServiceDependencyOnDeprecatedRisk(),
        new ServiceHasNoActiveSMERisk(),
        new ServiceHasLowBusFactorRisk(),
        new ServiceIsFragileRisk(),

        //Medium
        new ServiceHasNoPassportRisk(),
        new ServiceHasNoRecentReleasesRisk(),
        new ServiceIsDeprecatedRisk(),
        new ServiceUsesTooManyNonADOPTTechnologiesRisk(),
        new ServiceUsesAlienTechnologiesRisk(),
        new ServiceDependencyOnUnknownRisk(),
        new ServiceHasNoDepsDocumentationRisk(),
        new ServiceHasNoDisasterRecoveryDocumentationRisk(),
        new ServiceAuditIsRequiredRisk(),

        //Low
        new ServiceUsesHoldTechnologiesRisk(),
        new ServiceHasNoDescriptionRisk(),
        new ServiceHasNoTechRisk(),
        new ServiceHasNoStatusRisk(),
        new ServiceHasNoReleaseDocumentationRisk(),
        new ServiceHasNoDevelopmentDocumentationRisk(),
        new ServiceHasNoMonitoringDocumentationRisk(),
        new ServiceHasNoRecentAuditRisk(),

        //Info
        new ServiceIsSPOFRisk(),
        new ServiceHasNoHowToDocumentationRisk(),
        new ServiceHasNoPostMortemDocumentationRisk()
    ]
);
