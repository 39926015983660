import Risk, {RISK_SEVERITY_CRITICAL} from "../risk";

export class ServiceHasNoActiveContributorsRisk extends Risk {
    id = "NO_ACTIVE_CONTRIBUTORS";
    title = "Service has no active contributors";
    severity = RISK_SEVERITY_CRITICAL;
    explanation = [
        `Increased probability of loss of knowledge/<a href="https://www.instigatorblog.com/the-half-life-of-trust-reputation-and-social-capital/2009/11/19/">team trust</a> in the app and slow response on the any occurring incident.`
    ];
    description = (service) => {
        return `There is no active contributors left with the hands-on knowledge of this service.`
    };
    mitigation = [
        `Schedule knowledge transfer/service audit session within service owner team`,
        `Reassign service owner`,
        `Retire service`
    ];
    detailsLink =  "/docs/risk-glossary/#no-active-contributors";

    detectFunc = srv => srv.getContributors().find(x => isActive(srv, x)) === undefined
}

function isActive(srv, contributor) {
    const c = srv.lib.getContributor(contributor.Login);

    if (c) {
        return c.isActive();
    }

    return contributor.LastWeeks.Commits > 0
}