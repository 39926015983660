export const RISK_SEVERITY_CRITICAL = 'critical';
export const RISK_SEVERITY_HIGH = 'high';
export const RISK_SEVERITY_MEDIUM = 'medium';
export const RISK_SEVERITY_LOW = 'low';
export const RISK_SEVERITY_INFO = 'info';
export const RISK_SEVERITY_NONE = 'none';

export function getRiskSeverityColor(severity) {
    const severityColorMap = {};
    severityColorMap[RISK_SEVERITY_CRITICAL] = "#2E294E";
    severityColorMap[RISK_SEVERITY_HIGH] = "#FA4443";
    severityColorMap[RISK_SEVERITY_MEDIUM] = "#F9C80E";
    severityColorMap[RISK_SEVERITY_LOW] = "#81D4FA";
    severityColorMap[RISK_SEVERITY_INFO] = "#00E396";
    severityColorMap[RISK_SEVERITY_NONE] = "#ccc";

    return severityColorMap[severity] || "#fff";
}

export default class Risk {
    id;
    title;
    severity;
    description;
    explanation;
    mitigation;
    detailsLink;

    detectFunc = x => false;

    check(srv) {
        return this._detect(srv, this.detectFunc)
    }

    _detect(services, filter) {
        const oneService = !Array.isArray(services);
        if (oneService) {
            services = [services];
        }
        const filtered = services.filter(filter);
        if (oneService) {
            return filtered.length !== 0;
        }
        return filtered;
    }

    getSeverityColor() {
        return getRiskSeverityColor(this.severity);
    }
}