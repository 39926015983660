import Risk, {RISK_SEVERITY_LOW} from "../../risk";
import {isBoilerplateFolder} from "../../../domain/serviceDocs";

export class ServiceHasNoReleaseDocumentationRisk extends Risk {
    id = "NO_RELEASE_DOCUMENTATION";
    title = "Service has no release documentation";
    severity = RISK_SEVERITY_LOW;
    explanation = [
        `Release documentation should document procedures 
        of the service release/rollback to production/staging/whatever else environment.`,
        `Deployment (release/rollback) runbooks significantly reduce risks of knowledge-loss`
    ];
    description = (service) => {
        return `Release documentation for the service is missing`
    };
    mitigation = [
        `Introduce service release documentation`
    ];
    detailsLink = "https://github.com/Service360/ServiceBoilerplate/tree/master/docs/release";

    detectFunc = srv => (!srv.isExternal()) &&
        (!srv.getDocs().getReleaseDocs() || isBoilerplateFolder(srv.getDocs().getReleaseDocs()))
}