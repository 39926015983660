import {Button, Col, Container, FormGroup, Input, InputGroup, Row} from "reactstrap";
import React, {useState} from "react";
import {useInput} from "../../../hooks/useInput";
import {faWindowClose} from "@fortawesome/free-solid-svg-icons";
import {IconTooltip} from "../../App/components/Tooltip";

export function Contacts({contacts, setContacts}) {
    return <ul className="list-unstyled">
        {
            contacts.map((x, pos) => {
                const order = pos;
                const save = data => {
                    contacts[order] = data;
                    setContacts([...contacts]);
                }
                const remove = () => {
                    contacts.splice(order, 1);
                    setContacts([...contacts]);
                }
                return <li key={x + "_" + order}><Contact contact={x} save={save} remove={remove}/></li>
            })
        }
        <li>
            <Button onClick={() => {
                contacts.push({});
                setContacts([...contacts]);
            }} color={"success"}>Add contact</Button>
        </li>
    </ul>
}

function Contact({contact, save, remove}) {
    const [editable, setEditable] = useState(contact.Type === undefined && contact.Contact === undefined)
    const {value: type, bind: bindType} = useInput(contact.Type || "");
    const {value: contactData, bind: bindContactData} = useInput(contact.Contact || "");

    return <FormGroup>
        <InputGroup>
            {
                editable && <>
                    <Input type={"text"} name={"type"} {...bindType} placeholder={"Contact type (Email, Slack, etc)"}/>
                    <Input type={"text"} name={"contact"} {...bindContactData} placeholder={"Contact"}/>
                    <Button size={"sm"} className={"ml-1"} color={"primary"}
                            onClick={() => {
                                save({
                                    Type: type,
                                    Contact: contactData
                                });
                                setEditable(false);
                            }
                            }>Save</Button>
                    <Button className={"ml-1"} size={"sm"} color={"danger"} onClick={() => {
                        if (window.confirm("This contact will be removed. Do you want to proceed?")) {
                            remove()
                        }
                    }}><IconTooltip tip={"Remove contact"} icon={faWindowClose}/></Button>
                </>
            }
            {
                !editable && <Container fluid><Row>
                    <Col lg={3}>{type}:</Col>
                    <Col>{contactData}</Col>
                    <Col lg={2}>
                        <Button size={"sm"} className={"ml-1"} color={"primary"}
                                onClick={() => setEditable(true)}>Edit</Button>
                    </Col>
                </Row></Container>
            }
        </InputGroup>
    </FormGroup>
}