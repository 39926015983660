import {performance, score} from "../utils/contributors";

export default class Contributor {
    Services = {};

    isLoaded = false;
    SMEServices = [];
    SMERetiredServices = [];
    isSME = false;
    serviceLastContribution = {};
    serviceFirstContribution = {};

    Total = {
        Commits: 0,
        Additions: 0,
        Deletions: 0,
        NLOC: 0
    };

    LastWeeks = {
        Commits: 0,
        Additions: 0,
        Deletions: 0,
        NLOC: 0
    };

    LastContribution = null;

    constructor(lib, srv, contribution) {
        this.lib = lib;

        //Login is assigned here. Fucking magics
        const c = JSON.parse(JSON.stringify(contribution));
        Object.assign(this, c);

        this.SMEServices = [];
        this.Services = {};
        this._Services = {};
        this.Total = {
            Commits: 0,
            Additions: 0,
            Deletions: 0,
            NLOC: 0
        };
        this.LastWeeks = {
            Commits: 0,
            Additions: 0,
            Deletions: 0,
            NLOC: 0
        };
        this.LastContribution = null;
        this.FirstContribution = null;
        this.isSME = false;

        this.addContribution(srv, contribution);
    }

    getPerformance(type) {
        return performance(this, type);
    }

    getContributions() {
        return this.Services
    }

    addContribution(srv, contribution) {
        // this._logins[contribution.Login] = true;
        this.Services[srv.id] = contribution;
        this._Services[srv.id] = srv;
        if (contribution.isSME && !srv.isExternal()) {
            if (srv.isRetired()) {
                this.SMERetiredServices.push(srv.id);
            } else {
                this.isSME = true;
                this.SMEServices.push(srv.id);
            }
        }
        this.Total.Commits += contribution.Total.Commits;
        this.Total.Additions += contribution.Total.Additions;
        this.Total.Deletions += contribution.Total.Deletions;
        this.Total.NLOC += contribution.Total.NLOC;

        this.LastWeeks.Commits += contribution.LastWeeks.Commits;
        this.LastWeeks.Additions += contribution.LastWeeks.Additions;
        this.LastWeeks.Deletions += contribution.LastWeeks.Deletions;
        this.LastWeeks.NLOC += contribution.LastWeeks.NLOC;

        if (contribution.LastContribution !== 0) { //no idea how is this possible, but happened
            if (this.LastContribution < contribution.LastContribution) {
                this.LastContribution = contribution.LastContribution
            }

            if (this.getServiceLastContribution(srv.id) === undefined || this.getServiceLastContribution(srv.id) < contribution.LastContribution) {
                this.serviceLastContribution[srv.id] = contribution.LastContribution;
            }
        }

        if (contribution.FirstContribution !== 0) { //no idea how is this possible, but happened
            if (this.FirstContribution > contribution.FirstContribution || this.FirstContribution === null) {
                this.FirstContribution = contribution.FirstContribution

            }
            if (this.getServiceFirstContribution(srv.id) === undefined || this.getServiceFirstContribution(srv.id) > contribution.FirstContribution) {
                this.serviceFirstContribution[srv.id] = contribution.FirstContribution;
            }
        }
    }
    //
    // hasLogin(login) {
    //     return this._logins[login] !== undefined;
    // }

    isActive(libraryActivity = true) {
        if (libraryActivity) {
            const c = this.lib.getContributor(this.Login);

            if (c) {
                return c.LastWeeks.Commits > 0;
            }
        }

        return this.LastWeeks.Commits > 0;
    }

    getUserProfiles() {
        if (!this._userProfiles) {
            this._userProfiles = this.lib.org.getUserProfilesForContributor(this);
        }

        return this._userProfiles;
    }

    getName() {
        const profiles = this.getUserProfiles();

        if (!profiles) {
            return this.Login;
        }

        const names = profiles.map(x => x.getName()).join(", ");

        return names === "" ? this.Login : names;
    }

    async ready(ignoreIsLoaded = true) {
        if (this.isLoaded && !ignoreIsLoaded) {
            return;
        }

        //TODO: Load user profiles here

        const result = await this.lib.loader.get(this.lib.getMainLibrary().getDataURI() + "/contributors/" + this.Login + "/stats");

        this.stats = result.data;

        if (this.lib.isFiltered()) {
            const libServices = this.lib.getServices().map(x => x.getName());

            for (let year in this.stats.Stats) {
                for (let month in this.stats.Stats[year]) {
                    for (let service in this.stats.Stats[year][month]) {
                        if (libServices.indexOf(service) === -1) {
                            delete this.stats.Stats[year][month][service]
                        }
                    }

                    if (Object.keys(this.stats.Stats[year][month]).length === 0) {
                        delete this.stats.Stats[year][month];
                    }
                }
                if (Object.keys(this.stats.Stats[year]).length === 0) {
                    delete this.stats.Stats[year];
                }
            }
        }

        this.isLoaded = true;
    }

    getStats() {
        return this.stats.Stats
    }

    getSMECount() {
        return this.SMEServices.length;
    }

    getServiceLastContribution(serviceKey) {
        return this.serviceLastContribution[serviceKey];
    }

    getServiceFirstContribution(serviceKey) {
        return this.serviceFirstContribution[serviceKey];
    }

    getScore(notCached) {
        if (!this._SMEScore || notCached) {
            this._SMEScore = score(this);
        }
        return this._SMEScore;
    }

    getSMEWeightedScore(notCached) {
        return this.getScore(notCached) * Math.pow(this.getSMECount() + 1, 2)
    }

    getTech() {
        if (!this._tech) {
            this._tech = {};
            Object.values(this._Services).forEach(srv => {
                const techs = srv.getTech();
                if (!techs) {
                    return;
                }
                Object.keys(techs).forEach(tech => {
                    const techRadarTech = this.lib.getTech(tech);
                    if (techRadarTech) {
                        tech = techRadarTech.getName();
                    }
                    if (!this._tech[tech]) {
                        this._tech[tech] = []
                    }

                    this._tech[tech].push(srv.id);
                });
            });
        }
        return this._tech;
    }

    getTechLOCContribution(tech) {
        const t = this.getTech()[tech];
        if (t === undefined) {
            return 0;
        }

        return t
            .map(srv => {
                return parseInt(this.getContributions()[srv].Total.NLOC)
            })
            .reduce((a, b) => a + b, 0);
    }

    linkToGithubProfile() {
        return "https://github.com/" + this.Login;
    }

    linkToProfile() {
        return this.lib.linkToTeam() + "/contributor/" + this.Login;
    }

    linkToJourney() {
        return this.linkToProfile() + "/journey";
    }

    linkToContributions(year) {
        const link = this.linkToProfile() + "/contributions";

        if (year !== undefined) {
            return link + "/" + year;
        }

        return link;
    }

    linkToPortfolio() {
        return this.linkToProfile() + "/portfolio";
    }
}