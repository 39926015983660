import Risk, {RISK_SEVERITY_HIGH} from "../risk";

export class ServiceIsFragileRisk extends Risk {
    id = "IS_FRAGILE";
    title = "Service is fragile";
    severity = RISK_SEVERITY_HIGH;
    explanation = [
        `High potential of a cascading failure for your application landscape`
    ];
    description = (service) => {
        const n = service === undefined ? "> 3" : service.getHardDependencies().length;
        return `Service has <b>${n}</b> hard dependencies. This
                    makes service too fragile.`
    };

    mitigation = [
        `Consider switching to async communication`,
        `Consider reducing the amount of deps`,
        `Consider using anti-fragility approaches: timeouts, circuit breakers, etc`
    ];
    detailsLink = "/docs/risk-glossary/#service-is-fragile";

    detectFunc = srv => srv.getHardDependencies().length > 3;
}