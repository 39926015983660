import Risk, {RISK_SEVERITY_LOW} from "../../risk";
import {isBoilerplateFolder} from "../../../domain/serviceDocs";

export class ServiceHasNoDevelopmentDocumentationRisk extends Risk {
    id = "NO_DEVELOPMENT_DOCUMENTATION";
    title = "Service has no development documentation";
    severity = RISK_SEVERITY_LOW;
    explanation = [
        `Development documentation should describe 
        how to develop the service: how to prepare local environment, what kind of agreements do exist, 
        how to run tests, etc.`,
        `Development runbooks significantly reduce risks of knowledge-loss and reduce on-boarding time for the new contributors`
    ];
    description = (service) => {
        return `Development documentation for the service is missing.`
    };
    mitigation = [
        `Introduce service development documentation`
    ];
    detailsLink = "https://github.com/Service360/ServiceBoilerplate/tree/master/docs/development";

    detectFunc = srv => (srv.isWebService() || srv.isMobileApp() || srv.isLibrary() || srv.isEmbedded() || srv.isDesktopApp())
        && (!srv.getDocs().getDevelopmentDocs() || isBoilerplateFolder(srv.getDocs().getDevelopmentDocs()))
}