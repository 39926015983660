import Risk, {RISK_SEVERITY_LOW} from "../../risk";
import {isBoilerplateFolder} from "../../../domain/serviceDocs";

export class ServiceHasNoMonitoringDocumentationRisk extends Risk {
    id = "NO_MONITORING_DOCUMENTATION";
    title = "Service has no monitoring documentation";
    severity = RISK_SEVERITY_LOW;
    explanation = [
        `Monitoring documentation should document 
        service health monitoring measures/alerts: what kind of monitoring is present, 
        what triggers alerts, how a person should react on the alert to mitigate a threat.`,
        `Monitoring runbooks significantly reduce risks of knowledge-loss`,
        `Monitoring runbooks provide great transparency into how service is maintained and enables fast audits and
        assessments of alerting gaps`
    ];
    description = (service) => {
        return `Monitoring documentation for the service is missing. `
    };
    mitigation = [
        `Introduce service monitoring documentation`
    ];
    detailsLink = "https://github.com/Service360/ServiceBoilerplate/tree/master/docs/monitoring";

    detectFunc = srv => (!srv.isLibrary())
        && (!srv.getDocs().getMonitoringDocs() || isBoilerplateFolder(srv.getDocs().getMonitoringDocs()))
}