import React, {useState} from "react";
import {UncontrolledTooltip} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestionCircle} from "@fortawesome/free-solid-svg-icons";

let counter = 0;

export function Tooltip({children, tip}) {
    const [id] = useState("UncontrolledTooltip_" + counter++);

    return <>
        <span id={id}>{children}</span>
        <UncontrolledTooltip target={id} defaultOpen={false}>
            {tip}
        </UncontrolledTooltip>
    </>
}

export function IconTooltip({tip, icon = faQuestionCircle}) {
    return <Tooltip tip={tip}><FontAwesomeIcon icon={icon}/></Tooltip>
}

export function HelpTooltip({tip}) {
    return <>
        {
            tip &&
            <IconTooltip tip={tip}/>
        }
    </>
}