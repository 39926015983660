import Risk, {RISK_SEVERITY_LOW} from "../risk";

export class ServiceHasNoDescriptionRisk extends Risk {
    id = "NO_DESCRIPTION";
    title = "Service has no description";
    severity = RISK_SEVERITY_LOW;
    explanation = [
        `Potential loss of knowledge and wasted time during investigations (time is wasted for both investigator and SMEs)`
    ];
    description = (service) => {
        return `Description for the service is missing.`
    };

    mitigation = [
        `Add description to the ServicePassport`
    ];
    detailsLink = "/docs/risk-glossary/#service-has-no-description";

    detectFunc = srv => !srv.getDescription()

}