export default class ServiceDocs {
    service;
    docs;

    constructor(service, docs) {
        this.service = service;
        this.docs = docs;
    }

    getID() {
        return (this.docs.Repository + "/" + this.docs.RepositoryFolder)
            .replace(new RegExp("[/]+$"), "")
            .split("/").join("_")
    }

    isEmpty() {
        return !(this.docs && Object.values(this.docs.Root.Docs).length !== 0)
    }

    getLoader() {
        return this.service.loader;
    }

    getService() {
        return this.service;
    }

    getLibrary() {
        return this.service.lib;
    }

    getDocsFile(docPath) {
        if (this.isEmpty()) {
            return false;
        }

        const parts = docPath.slice(this.docs.Root.Path.length + 1).split('/');

        let selected = this.docs.Root;
        for (let part of parts) {
            if (selected.Docs[part] === undefined) {
                return false;
            }
            selected = selected.Docs[part]
        }

        if (selected.Path !== docPath) {
            return false;
        }

        return selected
    }

    getTree() {
        return this.docs.Root;
    }

    getFullDocPath(path) {
        if (this.isEmpty()) {
            return path;
        }
        return this.docs.Root.Path + path;
    }

    async getBinaryContent(path) {
        const fullPath = this.getLibrary().getDataURI() + "/docs/" + this.getID() + path;

        const content = await this.getLoader()
            .get(fullPath);

        return content.data
    }

    async getDocContent(docPath) {
        const doc = this.getDocsFile(docPath);
        if (doc === false) {
            throw Error("Document not found")
        }
        if (Object.keys(doc.Docs).length !== 0) {
            throw Error("Nothing to load, this is folder")
        }
        if (doc.Content === "") {
            const content = await this.getLoader()
                .get(
                    this.getLibrary().getDataURI() + "/docs/" + this.getID() + "/" + docPath,
                );
            doc.Content = content.data;
        }
        return doc.Content
    }

    getADRDocsPath() {
        return this.getFullDocPath("/adr");
    }

    getADRDocs() {
        return this.getDocsFile(this.getADRDocsPath())
    }

    getReleaseDocsPath() {
        return this.getFullDocPath("/release");
    }

    getReleaseDocs() {
        return this.getDocsFile(this.getReleaseDocsPath())
    }

    getAuditDocsPath() {
        return this.getFullDocPath("/audit");
    }

    getAuditDocs() {
        return this.getDocsFile(this.getAuditDocsPath())
    }

    getDevelopmentDocsPath() {
        return this.getFullDocPath("/development");
    }

    getDevelopmentDocs() {
        return this.getDocsFile(this.getDevelopmentDocsPath())
    }

    getDisasterRecoveryDocsPath() {
        return this.getFullDocPath("/disaster-recovery")
    }

    getDisasterRecoveryDocs() {
        return this.getDocsFile(this.getDisasterRecoveryDocsPath())
    }

    getMonitoringDocsPath() {
        return this.getFullDocPath("/monitoring")
    }

    getMonitoringDocs() {
        return this.getDocsFile(this.getMonitoringDocsPath())
    }

    getPostMortemDocsPath() {
        return this.getFullDocPath("/post-mortem")
    }

    getPostMortemDocs() {
        return this.getDocsFile(this.getPostMortemDocsPath())
    }

    getHowToDocsPath() {
        return this.getFullDocPath("/how-to")
    }

    getHowToDocs() {
        return this.getDocsFile(this.getHowToDocsPath())
    }
}

export function isBoilerplateFolder(doc) {
    return doc && doc.Docs && (doc.Docs["DELETEME.md"] !== undefined);
}