import React, {useState} from 'react';
import Tour from 'reactour'
import './intro.css';

let shownTillTheEnd = false;

export default function MainIntro({user}) {
    const [isOpen, setOpen] = useState(user.getEmail() === "demo@service360.io");

    if (shownTillTheEnd) {
        return null;
    }

    const tourConfig = [
        {
            position: 'center',
            content: <div>
                <h3>Welcome to Service360!</h3>
                <p style={{textAlign: "left"}}>We are glad to meet you in our quick interactive tour
                    which will show you main features of Service360.</p>

                <p style={{textAlign: "left"}}>This tour will take you no more than 3-5 minutes</p>
            </div>,
        }
        ,
        {
            position: 'center',
            content: <div>
                <h4>What is Service360?</h4>
                <p>Service360 is a documentation and risk-management platform specifically created for <b>custom-built</b> application landscapes with enterprise needs in mind.</p>
                <p>Documentation presented here has least chances to be outdated (we will show you why in a
                    second).</p>
                <p>And we are more than just documentation - we also help you to <b>detect</b> and <b>mitigate</b> most common
                    enterprise IT risks.</p>
            </div>,
        },
        {
            position: 'center',
            content: <div>
                <h4>Let's start</h4>
                <p>Main entity in every application landscape is a <b>Service</b> (or an Application if you wish).</p>
                <p><b>Services</b> in Service360 are organized into <b>Libraries</b>.</p>
                <p>
                    Based on your organisation size and complexity of the landscape you can have
                    one Library or many of those. It is up to you to decide how to split your Services
                    into Libraries: by main functionality, by stack, by department, by size - you know better.
                </p>
                <p>
                    We recommend to start with one big <b>Library</b>.
                </p>
            </div>,
        },
        {
            selector: 'body',
            position: 'center',
            title: "Library overview",
            content: <div>
                <p>This page is an overview of all Libraries created in your Organisation.</p>
            </div>,
        },
        {
            selector: 'div[data-lib-id="SampleLib"]',
            content: <div>
                <p>This is a Sample library which will show you main features of Service360.</p>
            </div>
        },
        {
            selector: 'div[data-lib-id="SampleLib"] .aggregated-info',
            content: 'For each Library you will see a brief overview of its current state...',
        },
        {
            selector: 'div[data-lib-id="SampleLib"] .teams',
            content: '...which teams own Applications in this Library...',
        },
        {
            selector: 'div[data-lib-id="SampleLib"] .contexts',
            content: <p>... and which <b>contexts</b> are represented in it. <br/><br/>Don't worry in a minute you will
                know what "Context" is :)</p>,
        },
        {
            selector: 'div[data-lib-id="SampleLib"] .go-to-lib a',
            action: x => {
                shownTillTheEnd = true;
            },
            content: <div>
                <p>Let's see what is inside!</p>
                <p>Click the link!</p>
            </div>,
            position: "right"
        }
    ];

    return <Tour
        onRequestClose={x => setOpen(false)}
        steps={tourConfig}
        isOpen={isOpen}
        maskClassName="mask"
        className="helper"
        rounded={5}
    />;


}