import Risk, {RISK_SEVERITY_MEDIUM} from "../risk";

const RecentReleaseDaysThreshold = 90;

export class ServiceHasNoRecentReleasesRisk extends Risk {
    id = "NO_RECENT_RELEASES";
    title = "Service has no releases in the last " + RecentReleaseDaysThreshold + " days";
    severity = RISK_SEVERITY_MEDIUM;
    explanation = [
        `Increased probability of loss of knowledge/<a href="https://www.instigatorblog.com/the-half-life-of-trust-reputation-and-social-capital/2009/11/19/">team trust</a> in the app and slow response on the any occurring incident. `
    ];
    description = (service) => {
        const n = service === undefined ? RecentReleaseDaysThreshold : service.getDaysSinceLastRelease();
        return `Service was not released in the last <b>${n}</b> days. <br/>
                    There is a risk of losing knowledge. `
    };

    mitigation = [
        `Release an app`,
        `If active service development is finished, consider marking it with Context "MaintenanceOnly" in the ServicePassport`
    ];

    detailsLink = "/docs/risk-glossary/#service-has-no-recent-releases";

    detectFunc = srv => {
        if (srv.isAbandoned()) { // skipping the check for abandoned services. There is separate risk for that.
            return false;
        }

        // MAINTENANCE_ONLY services are unaffected by this risk (thou they will be caught by
        // Abandoned risk)
        if (srv.isInMaintenanceOnlyMode()) {
            return false
        }

        return !srv.isActive();
    }
}