import Risk, {RISK_SEVERITY_HIGH} from "../risk";

export class ServiceHasNoOwnerRisk extends Risk {
    id = "NO_OWNER";
    title = "Service has no owner";
    severity = RISK_SEVERITY_HIGH;

    detectFunc = srv => !srv.getOwner();

    enables = [
        `Feature: Owner virtual library`
    ];

    explanation = [
        `True collective ownership in the enterprise environment is extremely rare (if ever existed). 
            In most cases people tend to treat “collective” as “no ones”. In IT “no ones” services introduce a lot of 
            additional failure modes to the business: starting from usual operations failures and to possible security issues/leakages.`,
        `High risk of software architecture/domain fragmentation/bloat.`
    ];
    description = (service) => `Owner for the service is missing.`;
    mitigation = [
        "Define the Owner in the Service Passport"
    ];
    detailsLink = "/docs/risk-glossary/#service-has-no-owner";
}