export default class ContributionsByYear {
    isLoaded = false;
    overview = {};

    constructor(lib, year) {
        this.lib = lib;
        this.year = year;
    }

    async ready() {
        const d = await this.lib.loader.get(this.lib.getDataURI() + "/contributionsByYear/" + this.year);
        this.overview = d.data;

        this.overview.AllContributions = JSON.parse(JSON.stringify(this.overview.Contributions));

        // Filtered libraries support
        Object.keys(this.overview.Contributions).forEach(week => {
            Object.keys(this.overview.Contributions[week]).forEach(service => {
                if (!this.lib.getServiceByName(service)) {
                    delete this.overview.Contributions[week][service];
                }
            });

            if (Object.values(this.overview.Contributions[week]).length === 0) {
                delete(this.overview.Contributions[week]);
            }
        });

        this.isLoaded = true;
    }

    getContributions() {
        return this.overview.Contributions || {};
    }

    getActiveContributorsInTheWeek(week) {
        const activeContributors = {};

        for (let i=0; i < 4; i++) { //active user == any contribution in the last 4 weeks
            let checkWeek = week - i;
            if (checkWeek < 1) {
                break;
            }

            const contributions = this.getContributions()[checkWeek + ""];

            if (!contributions) {
                continue;
            }

            for (let serviceContribution of Object.values(contributions)) {
                for (let contribution of serviceContribution) {
                    activeContributors[contribution.Login] = true;
                }
            }

        }

        return Object.keys(activeContributors);
    }
}