import React, {useState} from 'react';
import cx from 'classnames';
import UncontrolledTooltip from "reactstrap/lib/UncontrolledTooltip";

import "./index.css"
import {riskDetector} from "../../../../risks/detector";

export function RiskBadge({className, tooltip, style, severity, type=false}) {
    const [id] = useState("id-" + Math.random().toString(16).replace(".", ""));

    if (type === false) {
        switch (severity) {
            case "critical":
                type = "dark";
                break;
            case "high":
                type = "danger";
                break;
            case "medium":
                type = "warning";
                break;
            case "low":
                type = "info";
                break;
            default:
                type = "success";
                break;
        }
    }

    const classes = "badge badge-" + type + " badge-dot";
    return (
        <>
            <i className={cx("risk-badge", className)} id={id} style={style}>
                <span className={classes}>&nbsp;</span>
            </i>
            {tooltip &&
            <UncontrolledTooltip target={id} innerClassName={"risk-badge-tooltip"}>
                <div dangerouslySetInnerHTML={{__html: tooltip}} />
            </UncontrolledTooltip>
            }
        </>
    );
}

export function ServiceRiskBadge({service, className, style}) {
    if (service.isExternal()) {
        return <RiskBadge type={"alternate"} className={className} style={style}
                          tooltip={"Service is external. Risk detection is switched off"}/>
    }

    if (service.isRetired()) {
        return <RiskBadge type={"alternate"} className={className} style={style}
                          tooltip={"Service is RETIRED. Risk detection is switched off"}/>
    }

    const risks = riskDetector.ListRisks(service);
    return <RisksBadgeWithTooltip risks={risks} className={className} style={style}/>
}

export function RisksBadgeWithTooltip({risks, className, style}) {
    let issues;
    let topSeverity;

    switch (true) {
        case risks["critical"].length > 0:
            issues = "";
            topSeverity = "critical";
            break;
        case risks["high"].length > 0:
            issues = "";
            topSeverity = "high";
            break;
        case risks["medium"].length > 0:
            issues = "";
            topSeverity = "medium";
            break;
        case risks["low"].length > 0:
            issues = "";
            topSeverity = "low";
            break;
        default:
            issues = "All good. No issues detected";
            topSeverity = "ok";
            break;
    }

    for (const severity of Object.keys(risks)) {
        for (const risk of Object.keys(risks[severity])) {
            issues += "<p>" + risks[severity][risk].title + "</p>"
        }
    }

    return <RiskBadge severity={topSeverity} tooltip={issues} className={className} style={style}/>;
}