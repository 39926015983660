import React from 'react';
import PageTitle from "../App/components/PageTitle";
import {Alert, Card, CardFooter, CardHeader, Col, Container, ListGroup, ListGroupItem, Row} from "reactstrap";
import {Link} from "react-router-dom";
import {formatTimestampToDateTime} from "../../utils/date";

import './overview.css'

export default function OrganisationOverview({organisation}) {
    return <>
        <PageTitle
            heading={organisation.name}
            subheading={organisation.description}
            icon="pe-7s-car icon-gradient bg-mean-fruit"
        />
        <Container fluid>
            {
                organisation.getNonFilterLibraries().map((lib, i) => {
                    return (
                        <Library library={lib} key={lib.id}/>
                    )
                })
            }
        </Container>
    </>;
}

function Library({library}) {
    return <Card className="mb-3" data-lib-id={library.id}>
        <CardHeader className="card-header-tab z-index-6">
            <div
                className="card-header-title font-size-lg text-capitalize font-weight-normal go-to-lib">
                <i className="header-icon lnr-charts icon-gradient bg-happy-green"> </i>
                <Link to={library.link()}>{library.name}</Link>
            </div>
        </CardHeader>

        <Row>
            <Col sm="12" md="2" className="align-self-center aggregated-info">
                <div className="widget-chart">
                    <div className="widget-chart-content">
                        <div
                            className="widget-numbers fsize-3">
                            {library.details.servicesCount}
                        </div>
                        <div className="widget-subheading pt-1">
                            Services
                        </div>
                    </div>
                </div>
                <div className="divider"/>
                <div className="widget-chart">
                    <div className="widget-chart-content">
                        <div
                            className="widget-numbers fsize-3">
                            {library.details.contributorsCount}
                        </div>
                        <div className="widget-subheading pt-1">
                            Contributors
                        </div>
                    </div>
                </div>

            </Col>
            <Col sm="12" md="3" className={"teams"} style={{
                padding: "2rem"
            }}>
                <h5 className="text-muted text-uppercase font-size-md opacity-5 font-weight-normal">Owners/Teams:</h5>
                {
                    library.getOwnerLibraries().length !== 0 &&
                    <ul className="list-unstyled">
                        {
                            library.getOwnerLibraries().sort((a,b) => a.shortName > b.shortName ? 1 : -1).map((owner, i) => {
                                return <li key={"owner" + i}>
                                    <Link to={owner.link()}>{owner.shortName}</Link>
                                </li>
                            })
                        }
                        <li key={"ownerless"}>
                            <Link to={library.getOwnerlessLibrary().link()}>{library.getOwnerlessLibrary().shortName}</Link>
                        </li>
                    </ul>
                }
                {
                    library.getOwnerLibraries().length === 0 &&
                    <Alert color={"warning"}>
                        <h4 className="alert-heading">Service owners are not found</h4>
                        Consider marking your services with <a
                        href="https://service360.io/docs/service-passport/#owner">Owners</a> to bring more clarity and
                        simplify
                        library management
                    </Alert>
                }
            </Col>
            <Col sm="12" md="3" className={"contexts"} style={{
                padding: "2rem"
            }}>
                <h5 className="text-muted text-uppercase font-size-md opacity-5 font-weight-normal">Contexts:</h5>
                {
                    library.getContextLibraries().length !== 0 &&
                    <ul className="list-unstyled">
                        {
                            library.getContextLibraries().sort((a,b) => a.shortName > b.shortName ? 1 : -1).map((ctx, i) => {
                                return <li key={"context" + i}>
                                    <Link to={ctx.link()}>{ctx.shortName}</Link>
                                </li>
                            })
                        }
                    </ul>
                }
                {
                    library.getContextLibraries().length === 0 &&
                    <Alert color={"warning"}>
                        <h4 className="alert-heading">Contexts are not found</h4>
                        Consider marking your services with <a
                        href="https://service360.io/docs/service-passport/#context">Contexts</a> to bring more clarity
                        and simplify library management
                    </Alert>
                }
            </Col>
            <Col sm="12" md={4} style={{
                padding: "2rem"
            }}>
                <h5 className="text-muted text-uppercase font-size-md opacity-5 font-weight-normal">
                    Last releases</h5>
                {
                    library.details.lastReleases && library.details.lastReleases.length > 0 &&
                    <LastReleases library={library}/>
                }
                {
                    (!library.details.lastReleases || library.details.lastReleases.length === 0) &&
                    <span className="text-muted">No releases found</span>
                }
            </Col>
        </Row>
        <CardFooter className="text-center d-block p-3">
            <div>
                <small className="text-muted">Updated at: </small>
                <small className="text-muted">{library.details.updatedAt.slice(0, 19).replace("T", " ")}</small>
            </div>
        </CardFooter>
    </Card>
}

function LastReleases({library}) {
    return <ListGroup flush className={"last-releases-overview mr-3"}>
        {
            library.details.lastReleases.map((release, i) => {
                return <Release release={release} libraryLink={library.link()} key={"releasesoverview" + i}/>
            })
        }
    </ListGroup>
}

function Release({release, libraryLink}) {
    return <ListGroupItem>
        <div className="widget-content p-0">
            <div className="widget-content-wrapper">
                <div className="widget-content-left">
                    <div className="widget-heading">
                        <Link to={libraryLink + "/registry/" + release.serviceName}>{release.serviceName}</Link>
                    </div>
                    <div className="widget-subheading">
                        {release.name}
                    </div>
                </div>
                <div className="widget-content-right">
                    {formatTimestampToDateTime(release.releasedAt)}
                </div>
            </div>
            {release.description}
        </div>
    </ListGroupItem>
}