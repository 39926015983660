const DayInMilliSeconds = 24 * 60 * 60 * 1000;
const WeekInMilliSeconds = 7 * DayInMilliSeconds;

export function timestampToDate(ts) {
    if (!(ts instanceof Date)) {
        ts = new Date(ts * 1000)
    }

    return ts
}

export function formatTimestampToDateTime(ts) {
    return timestampToDate(ts).toLocaleString()
}

export function formatTimestampToDate(ts) {
    return timestampToDate(ts).toLocaleDateString()
}

export function formatTimestampToWeekNumber(ts, withDates=true) {
    ts = timestampToDate(ts);
    const [year, weekNo] = getWeekNumber(ts);

    return year+" - CW"+weekNo + (withDates? " ("+formatTimestampToDate(ts)+")":"");
}

export function daysSince(ts) {
    return Math.round((new Date() - timestampToDate(ts)) / DayInMilliSeconds)
}

export function weeksSince(ts) {
    return Math.round((new Date() - timestampToDate(ts)) / WeekInMilliSeconds)
}

export function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
    // Get first day of year
    var yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
    // Calculate full weeks to nearest Thursday
    var weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
    // Return array of year and week number
    return [d.getUTCFullYear(), weekNo];
}