import React from "react";
import cx from 'classnames';
import {Link} from "react-router-dom";

export default function HeaderLogo({org, library}) {
    return <div className={cx("app-header__logo", {"with-org": org}, {"with-library": library})}>
        <Link to={"/"}><div className="logo-src"></div></Link>
        {org &&<Link className="org-link" to={org.linkToOverview()}>{org.getName()}</Link>}
        {library && library.isFiltered() && <Link className="lib-link" to={library.parentLib.link()}>{library.parentLib.getName()}</Link>}
        {library && !library.isFiltered() && <Link className="lib-link" to={library.link()}>{library.getName()}</Link>}
    </div>
}