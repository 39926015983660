import Risk, {RISK_SEVERITY_HIGH} from "../risk";

export class ServiceDependencyOnAbandonedRisk extends Risk {
    id = "DEPENDENCY_ON_ABANDONED";
    title = "Service depends on the Abandoned service";
    severity = RISK_SEVERITY_HIGH;
    explanation = [
        `Abandoned services yield a high risk of an outage/longer recovery time`
    ];
    description = (service) => {
        const descr = `Service depends on the abandoned service.`;

        if (!service) {
            return descr;
        }

        return descr + `: <ul><li>`+getAbandonedDeps(service)
            .map(x => `<a href="`+x.linkToRegistryOverview()+`">`+x.getName()+`</a>`)
            .join("</li><li>")+ `</li></ul>`
    };

    mitigation = [
        `Replace abandoned service with its alternative`,
        `Release the abandoned service`,
        `Deprecate the service`,
        `Retire the service`
    ];
    detailsLink = "/docs/risk-glossary/#service-depends-on-the-abandoned-service";
    detectFunc = srv => {
        if (!srv.hasDependencies()) {
            return false;
        }

        return getAbandonedDeps(srv).length !== 0
    }
}

function getAbandonedDeps(srv) {
    return Object.keys(srv.srv.PlantUML.Nodes).filter(name => {
        if (name === srv.getName()) {
            return false;
        }

        const depSrv = srv.lib.getService(name);
        if (depSrv) {
            return depSrv.isAbandoned()
        }

        return false;
    }).map(x => srv.lib.getService(x));
}