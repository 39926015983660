import React, {Fragment, useState} from 'react';
import Drawer from "react-motion-drawer";
import PerfectScrollbar from "react-perfect-scrollbar";
import Hamburger from "react-hamburgers";
import {ListGroup, ListGroupItem} from "reactstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faChevronRight} from "@fortawesome/free-solid-svg-icons";
import {HelpTooltip} from "../Tooltip";
import {NavLink} from "react-router-dom";

export default function RightDrawer({org, library}) {
    const [open, setOpen] = useState(false);

    if (!library) {
        return null;
    }

    return <Fragment>
        <Drawer
            right
            className="drawer-content-wrapper p-0"
            width={450}
            open={open}
            onChange={o => setOpen(o)}
            noTouchOpen={false}
            noTouchClose={false}
        >
            <PerfectScrollbar>
                <div className="drawer-nav-btn">
                    <Hamburger
                        active={open}
                        type="elastic"
                        onClick={() => setOpen(false)}
                    />
                </div>
                <LibrariesList library={library} org={org} />
            </PerfectScrollbar>
        </Drawer>

        <div className="header-btn-lg">
            <Hamburger
                active={open}
                type="elastic"
                onClick={() => setOpen(!open)}
            />
        </div>
    </Fragment>
}

function LibrariesList({org, library}) {
    if (!library) {
        return null;
    }

    let parentLib = library.isFiltered() ? library.parentLib : library;

    return <Fragment>
        <h3 className="drawer-heading">
            Main library
        </h3>
        <div className="drawer-section p-0">
            <div className="files-box">
                <ListGroup flush>
                    <LibraryNav currentLib={parentLib} selectedLib={library} />
                </ListGroup>
            </div>
        </div>

        <h3 className="drawer-heading">
            Libraries by Owner <HelpTooltip tip={"Library filtered by a service owner"}/>
        </h3>
        <div className="drawer-section p-0">
            <div className="files-box">
                <ListGroup flush>
                    {
                        parentLib.getOwnerLibraries().sort((a,b) => a.shortName > b.shortName ? 1 : -1).map(lib => {
                            return <LibraryNav currentLib={lib} selectedLib={library} key={"lib_"+lib.id}/>
                        })
                    }
                    <LibraryNav currentLib={parentLib.getOwnerlessLibrary()} selectedLib={library} key={"lib_ownerless"}/>
                </ListGroup>
            </div>
        </div>

        <h3 className="drawer-heading">
            Libraries by Context <HelpTooltip tip={"Library filtered by a service context"}/>
        </h3>
        <div className="drawer-section p-0">
            <div className="files-box">
                <ListGroup flush>
                    {
                        parentLib.getContextLibraries().sort((a,b) => a.shortName > b.shortName ? 1 : -1).map(lib => {
                            return <LibraryNav currentLib={lib} selectedLib={library} key={"lib_"+lib.id}/>
                        })
                    }
                </ListGroup>
            </div>
        </div>
    </Fragment>
}

function LibraryNav({selectedLib, currentLib}) {
    return <ListGroupItem className="pt-2 pb-2 pr-2">
        <div className="widget-content p-0">
            <div className="widget-content-wrapper">
                {
                    currentLib.id === selectedLib.id &&
                    <div className="widget-content-left fsize-2 mr-3 text-primary center-elem">
                        <FontAwesomeIcon icon={faChevronRight}/>
                    </div>
                }
                <div className="widget-content-left">
                    <div className="widget-heading font-weight-normal opacity-10">
                        <NavLink to={currentLib.link()}>
                            {currentLib.shortName || currentLib.name}
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
    </ListGroupItem>
}