import React, {createContext, useContext, useState} from 'react';

const initialState = {};
const store = createContext(initialState);
const {Provider} = store;

export default function useTheme() {
    return useContext(store);
}

const ThemeProvider = ({children}) => {
    const [mobileSidebarOpen, setMobileSidebarOpen] = useState(false);
    const [mobileTopNaviOpen, setMobileTopNaviOpen] = useState(false);

    return <Provider value={{
        mobileSidebarOpen,
        mobileTopNaviOpen,

        setMobileSidebarOpen,
        setMobileTopNaviOpen,

        toggleMobileSidebar: () => setMobileSidebarOpen(!mobileSidebarOpen),
        toggleMobileTopNavi: () => setMobileTopNaviOpen(!mobileTopNaviOpen),
    }}>{children}</Provider>;
};

export {store, ThemeProvider}