import Risk, {RISK_SEVERITY_MEDIUM} from "../risk";
import {TECH_ALIEN} from "../../domain/tech";

export class ServiceUsesAlienTechnologiesRisk extends Risk {
    id = "ALIEN_TECH_IN_USE";
    title = "Service uses ALIEN technologies";
    severity = RISK_SEVERITY_MEDIUM;
    explanation = [
        `Technologies not listed in the TechRadar are unknown to the company and may impose security or other business threats.`
    ];
    description = (service) => {
        let descr = `Service uses technologies not listed in TechRadar`;

        if (service) {
            descr += ": <ul><li>"+getAlienTechs(service).map(x => x.getName()).join("</li><li>") + "</li></ul>"
        }

        return descr
    };
    mitigation = [
        `Consider replacing ALIEN technologies with the ADOPT/TRIAL alternatives`,
        `Consider adding ALIEN technologies into the TechRadar`,
    ];
    detailsLink = "/docs/risk-glossary/#service-uses-alien-technologies";

    detectFunc = srv => {
        return getAlienTechs(srv).length !== 0
    }
}

function getAlienTechs(srv) {
    if (!srv.lib.getTechRadar().isDedicatedRadar()) {
        return [];
    }

    return Object.keys(srv.getTech() || {})
        .map(x => srv.lib.getTechRadar().getTech(x))
        .filter(x => x.getState() === TECH_ALIEN)
}