import Risk, {RISK_SEVERITY_LOW} from "../risk";

export class ServiceHasNoStatusRisk extends Risk {
    id = "NO_STATUS";
    title = "Service has no explicit status";
    severity = RISK_SEVERITY_LOW;

    description = (service) => `Status for the service is missing.`;
    detectFunc = srv => !srv.hasExplicitStatus();

    explanation = [
        `Explicit status of the service (production/in development/retired) provides better transparency to the
        service consumers and allows early mitigation of multiple risks`
    ];

    enables = [
        `Feature: Retired service archive`,
        `Risk detection: Service is deprecated`,
        `Risk detection: Service depends on the deprecated service`,
        `Risk detection: Service depends on the retired service`,
    ];

    mitigation = [
        "Define the Status in the Service Passport"
    ];

    detailsLink = "/docs/service-passport/#status";
}