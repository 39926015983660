import Risk, {RISK_SEVERITY_MEDIUM} from "../../risk";
import {isBoilerplateFolder} from "../../../domain/serviceDocs";

export class ServiceHasNoDisasterRecoveryDocumentationRisk extends Risk {
    id = "NO_DISASTER_RECOVERY_DOCUMENTATION";
    title = "Service has no disaster recovery documentation";
    severity = RISK_SEVERITY_MEDIUM;
    explanation = [
        `Disaster recovery documentation should describe 
        the process of the service recovery in cases of severe failures: database failure, network outage, backup procedures, etc.`,
        `Disaster recovery runbooks reduce time to recover service in case of failure`,
        `Disaster recovery runbooks make developers think about possible service failure modes and thus mitigate them`
    ];
    description = (service) => {
        return `Disaster-recovery documentation for the service is missing.`
    };
    mitigation = [
        `Introduce service disaster-recovery documentation`
    ];
    detailsLink = "https://github.com/Service360/ServiceBoilerplate/tree/master/docs/disaster-recovery";

    detectFunc = srv => (srv.isWebService()) && (!srv.getDocs().getDisasterRecoveryDocs() || isBoilerplateFolder(srv.getDocs().getDisasterRecoveryDocs()))
}