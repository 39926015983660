import Risk, {RISK_SEVERITY_LOW} from "../risk";

export class ServiceHasNoTechRisk extends Risk {
    id = "NO_TECH";
    title = "Service has no used technologies listed";
    severity = RISK_SEVERITY_LOW;

    detectFunc = srv => (srv.isWebService() || srv.isMobileApp() || srv.isLibrary()) && !srv.getTech();

    enables = [
        `Feature: TechRadar`,
        `Feature: Contributors tech stack portfolio`,
        `Risk detection: Too many non-ADOPT technologies used`,
        `Risk detection: Usage of HOLD technology`,
        `Risk detection: Usage of ALIEN technology`,
    ];

    explanation = [
        `Used technologies are a very important part of the Service Passport as they allow to detect various application landscape risks
        as well as ease onboarding of the new contributors.`
    ];
    description = (service) => `List of used technologies for the service is missing.`;
    mitigation = [
        "Define the Technologies in the Service Passport"
    ];
    detailsLink = "/docs/service-passport/#tech";
}