import Risk, {RISK_SEVERITY_MEDIUM} from "../risk";

const DaysThreshold = 360;

export class ServiceAuditIsRequiredRisk extends Risk {
    id = "AUDIT_IS_REQUIRED";
    title = "Service audit is required";
    severity = RISK_SEVERITY_MEDIUM;
    explanation = [
        `Documentation tends to become outdated with time. In order to keep it up-to-date regular reviews are necessary.
        Last review was done more than `+DaysThreshold+` days ago and it is recommended to schedule a new audit session`
    ];
    description = (service) => {
        const n = service === undefined ? DaysThreshold : service.getDaysSinceLastAudit();
        return `Service had no audit in the last <b>${n}</b> days. <br/>
                    There is a risk of outdated documentation.`
    };

    mitigation = [
        `Schedule <a href="https://github.com/Service360/ServiceBoilerplate/tree/master/docs/audit">service audit</a>`
    ];
    detailsLink = "/docs/risk-glossary/#service-audit-is-required";

    detectFunc = srv => {
        return srv.getDaysSinceLastAudit() > DaysThreshold;
    }
}