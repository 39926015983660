import React from 'react';
import {Link} from "react-router-dom";
import {formatTimestampToDateTime} from "../../../utils/date";
import {VerticalTimelineElement} from "react-vertical-timeline-component";
import Markdown from "../../App/components/Markdown";

export default function Release({release, showServiceLink, asTimelineItem}) {

    if (asTimelineItem) {
        const date = formatTimestampToDateTime(release.Date).split(" ");
        return <VerticalTimelineElement
            className="vertical-timeline-item"
            icon={<i
                className="badge badge-dot badge-dot-xl badge-success"> </i>}
            date={<span>{date[0]}<br/>{date[1]}</span>}
        >
            <h4 className="timeline-title">
                {
                    release.srv && showServiceLink &&
                    <span>
                        <Link to={release.srv.linkToRegistryReleases()}>{release.srv.getName()}</Link><br/>
                    </span>
                }
                {release.Release}
                {
                    release.Release !== release.Title &&
                    <><br /><span>{release.Title}</span></>
                }</h4>
            <Markdown source={release.Description}/>
        </VerticalTimelineElement>
    }

    return <div className="card release">
        <div className="card-header">
            <i className="fas fa-rocket"></i>&nbsp;
            {
                release.srv && showServiceLink &&
                <span>
                    <Link to={release.srv.linkToRegistryReleases()}>{release.srv.getName()}</Link>&nbsp;-&nbsp;
                </span>
            }
            {release.Release}
            {
                release.Release !== release.Title &&
                <span> - {release.Title}</span>
            }

            <a className="text-muted float-right" href={release.Link}>
                {formatTimestampToDateTime(release.Date)}
            </a>
        </div>
        <div className="card-body">
            <div className="card-text">
                <Markdown source={release.Description}/>
            </div>
        </div>
    </div>
}