import React, {useContext, useState} from 'react';
import {Card, CardHeader, CardBody, Container, Row, Col, FormGroup, Label, Input, Alert, Button} from "reactstrap";
import {HelpTooltip} from "../App/components/Tooltip";
import Loaded from "../App/components/Loaded";
import {useInput} from "../../hooks/useInput";
import {ProfileTypeGithub} from "../../domain/userProfile";
import {Contacts} from "./components/Contacts";
import Markdown from "../App/components/Markdown";
import SweetAlert from "sweetalert-react";

import './index.css';
import {Loader} from "react-loaders";
import BlockUi from "react-block-ui";

export default function Profile({org}) {
    const profile = org.getLoggedInUserProfile();

    return <Loaded item={profile} showContainer={true} message={"Loading your profile..."}>
        <Container>
        <ProfileEditor org={org} profile={profile}/>
        </Container>
    </Loaded>
}

function ProfileEditor({org, profile}) {
    const [editMode, setEditMode] = useState(false)

    const {value: name, bind: bindName} = useInput(profile.getName());
    const {value: info, bind: bindInfo} = useInput(profile.getInfo());
    const [contacts, setContacts] = useState(profile.getContacts());
    const {value: ghProfiles, bind: bindGhProfiles} = useInput(profile.getProfiles(ProfileTypeGithub).join(','));

    const [persistenceResult, setPersistenceResult] = useState({
        isLoading: false,
        error: false
    });

    const {isLoading, error} = persistenceResult;

    const save = async () => {
        const profiles = {}
        if (ghProfiles.trim() !== "") {
            profiles[ProfileTypeGithub] = ghProfiles.split(",").map(x => x.trim())
        }

        profile.data.Name = name;
        profile.data.Contacts = contacts;
        profile.data.Profiles = profiles;
        profile.data.Info = info;

        setPersistenceResult({
            ...persistenceResult,
            isLoading: true
        });
        try {
            await profile.save();
            setPersistenceResult({
                ...persistenceResult,
                isLoading: false,
            });
            setEditMode(false);
        } catch (e) {
            setPersistenceResult({
                ...persistenceResult,
                isLoading: false,
                error: e
            });
        }
    };

    return <Card className={"profile-editor"}>
        <CardHeader className="card-header-tab">
            <div className="card-header-title">
                <i className="header-icon lnr-user icon-gradient bg-tempting-azure"> </i>
                <span style={{paddingRight: "5px"}}>My {org.getName()} profile</span>
            </div>
            <div className="btn-actions-pane-right">
                {
                    !editMode && <Button color={"primary"} onClick={() => setEditMode(true)}>Edit</Button>
                }
                {
                    editMode && <Button color={"success"} onClick={save}>Save</Button>
                }
            </div>
        </CardHeader>
        <BlockUi tag="div" blocking={isLoading}
                 message={"Saving"}
                 loader={<Loader active type="ball-pulse"/>}>
            <SweetAlert
                title="Error"
                confirmButtonColor=""
                show={error !== false}
                text={error ? error.message + "\n\n" + error.response.data : ""}
                confirmButtonText="Okay"
                onConfirm={() => {
                    setPersistenceResult({
                        ...persistenceResult,
                        error: false
                    });
                }}
                type="error"/>
            <CardBody>
                {
                    !editMode && <Container>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Name: </Label>
                                    {
                                        name !== "" && <> {name}</>
                                    }
                                    {
                                        name === "" && <Alert color={"info"}>
                                            <h5 className="alert-heading">Welcome stranger!</h5>
                                            Consider entering your name
                                        </Alert>
                                    }
                                </FormGroup>
                                <FormGroup>
                                    <Label>Email: </Label> {profile.getEmail()}
                                </FormGroup>
                                {
                                    info !== "" &&
                                    <FormGroup>
                                        <Label>Public info</Label>
                                        <Markdown>{info}</Markdown>
                                    </FormGroup>
                                }
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Contacts</Label>
                                    {
                                        profile.getContacts().length !== 0 && <ul>
                                            {profile.getContacts().map(x => <li>{x.Type}: {x.Contact}</li>)}
                                        </ul>
                                    }
                                    {
                                        profile.getContacts().length === 0 && <Alert color={"info"}>
                                            <h5 className="alert-heading">No contacts found</h5>
                                            Consider adding at least one contact method
                                        </Alert>
                                    }
                                </FormGroup>
                                <FormGroup>
                                    <Label>Linked Github profiles</Label>
                                    {
                                        profile.getProfiles(ProfileTypeGithub).length !== 0 && <ul>
                                            {profile.getProfiles(ProfileTypeGithub).map(x => <li>{x}</li>)}
                                        </ul>
                                    }
                                    {
                                        profile.getProfiles(ProfileTypeGithub).length === 0 && <Alert color={"info"}>
                                            <h5 className="alert-heading">No github profiles linked</h5>
                                            Consider linking your github profile
                                        </Alert>
                                    }

                                </FormGroup>
                            </Col>
                        </Row>
                    </Container>
                }
                {
                    editMode && <Container>
                        <Row>
                            <Col>
                                <FormGroup>
                                    <Label>Name</Label>
                                    <Input type="text"  {...bindName}
                                           placeholder="Name"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Email</Label>
                                    <Input type="email" value={profile.getEmail()} readOnly={true}
                                           placeholder="email"/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Public info</Label>
                                    <Input type="textarea"  {...bindInfo}
                                           placeholder="Add some info about yourself..."/>
                                </FormGroup>
                            </Col>
                            <Col>
                                <FormGroup>
                                    <Label>Contacts</Label>
                                    <Contacts contacts={contacts} setContacts={setContacts}/>
                                </FormGroup>
                                <FormGroup>
                                    <Label>Linked Github profiles <HelpTooltip
                                        tip={"Comma-separated list of github logins"}/></Label>
                                    <Input type="text"  {...bindGhProfiles}/>
                                </FormGroup>
                            </Col>
                        </Row>
                    </Container>
                }
            </CardBody>
        </BlockUi>
    </Card>
}