import UserProfile from "./userProfile";

export default class Team {

    constructor(org, data) {
        this.org = org
        this.data = data

        this.lead = new UserProfile(org, data.Lead);

        data.Members = data.Members || [];

        this.members = data.Members.map(x => new UserProfile(org, x))
    }

    getContacts() {
        return this.data.Contacts;
    }

    getName() {
        return this.data.Name;
    }

    getLead() {
        return this.lead;
    }

    getMembers() {
        return this.members;
    }

    getMission() {
        return this.data.Mission;
    }
}