import Risk, {RISK_SEVERITY_MEDIUM} from "../risk";

export class ServiceHasNoDepsDocumentationRisk extends Risk {
    id = "NO_DEPENDENCIES_DOCS";
    title = "Service has no dependencies documentation";
    severity = RISK_SEVERITY_MEDIUM;
    explanation = [
        `Missing service dependency documentation blocks early detection of variety of other risks and prolongs new team members onboarding time.`
    ];

    enables = [
        `Feature: BigPicture`,
        `Feature: Service dependencies`,
        `Risk detection: Service depends on the deprecated service`,
        `Risk detection: Service depends on the abandoned service`,
        `Risk detection: Service depends on an unknown service`,
        `Risk detection: Service depends on the retired service`,
        `Risk detection: Service is a SPOF`,
        `Risk detection: Service is fragile`,
    ];

    description = (service) => {
        return `Service has no <a href="https://service360.io/docs/service-dependency-graph/">dependency documentation.</a>`
    };
    mitigation = [
        `Introduce <a href="https://service360.io/docs/service-dependency-graph/">Service Dependency Graph</a>`
    ];
    detailsLink = "/docs/risk-glossary/#service-has-no-dependencies-documentation";

    detectFunc = srv => (!srv.isExternal() && !srv.isRetired()) && !srv.hasDependencies()

}