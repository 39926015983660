import Risk, {RISK_SEVERITY_HIGH} from "../risk";

export class ServiceHasLowBusFactorRisk extends Risk {
    id = "LOW_BUS_FACTOR";
    title = "Service has low bus-factor";
    severity = RISK_SEVERITY_HIGH;
    explanation = [
        `Increased probability of loss of knowledge/<a href="https://www.instigatorblog.com/the-half-life-of-trust-reputation-and-social-capital/2009/11/19/">team trust</a> in the app and slow response on the any occurring incident. `
    ];
    description = (service) => {
        return `There is only one active contributor with hands-on knowledge of the service`
    };
    mitigation = [
        `Schedule knowledge transfer/audit session`,
        `Reassign service owner`,
        `Deprecate/retire service`,
    ];
    detailsLink = "/docs/risk-glossary/#service-has-low-bus-factor";

    detectFunc = srv => srv.getContributors().filter(x => isActive(srv, x)).length === 1
}

function isActive(srv, contributor) {
    const c = srv.lib.getContributor(contributor.Login);

    if (c) {
        return c.isActive();
    }

    return contributor.LastWeeks.Commits > 0
}