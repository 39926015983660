import Risk, {RISK_SEVERITY_INFO} from "../../risk";
import {isBoilerplateFolder} from "../../../domain/serviceDocs";

export class ServiceHasNoPostMortemDocumentationRisk extends Risk {
    id = "NO_POSTMORTEM_DOCUMENTATION";
    title = "Service has no post-mortem reports";
    severity = RISK_SEVERITY_INFO;
    explanation = [
        `Every service will fail sooner or later. 
        Every failure is a learning opportunity and this opportunity should not be missed. Every 
        significant/customer-impacting failure should be documented in order for the future maintainers of the service 
        to learn and not repeat the same mistakes again`,
        `Post-mortem reports are a concentrated experience. They provide huge learning opportunities for the team.`
    ];
    description = (service) => {
        return `Post-mortem documentation for the service is missing.`
    };
    mitigation = [
        `Introduce service post-mortem reports`
    ];
    detailsLink = "https://github.com/Service360/ServiceBoilerplate/tree/master/docs/post-mortem";

    detectFunc = srv => (srv.isWebService() || srv.isMobileApp() || srv.isEmbedded() || srv.isDesktopApp()
        )&&
        (!srv.getDocs().getPostMortemDocs() || isBoilerplateFolder(srv.getDocs().getPostMortemDocs()))
}